import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import config from '@/config';
import store from '@/store';
import router from "@/router";

// keep track of the current refresh request, don't call it if it's in progress
// let refreshPromise: Promise<string> = null;
let refreshPromise: any = null;

export class ApiService {
	private static _instance: ApiService;
	private readonly axiosInstance: AxiosInstance;
	private readonly baseUrl: string;

	private constructor() {
		this.baseUrl = config.apiBaseURI;

		this.axiosInstance = axios.create({
			baseURL: config.apiBaseURI,
			// withCredentials: true,
			headers: {
				'Content-Type': 'application/json',
			},
		});

		this.initializeRequestInterceptor();
		this.initializeResponseInterceptor();
	}

	public static get Instance() {
		// @typescript-eslint/explicit-module-boundary-types
		return this._instance || (this._instance = new this());
	}

	public tryToRefreshToken = async () => {
		// Try request again with new token

		const resRefresh = await this.post('refresh', {}, {
			withCredentials: true,
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		});

		// const resRefresh = await this.axiosInstance.request({
		// 	url: this.baseUrl + 'refresh',
		// 	method: 'post',
		// 	withCredentials: true,
		// 	xsrfCookieName: "XSRF-TOKEN",
		// 	xsrfHeaderName: "X-XSRF-TOKEN",
		// 	headers: {
		// 			'Accept': 'application/json',
		// 			'Content-Type': 'application/json',
		// 	},
		// 	data: {}
		// });


		// console.log('>> refresh resRefresh', resRefresh);
		if (resRefresh.status === 200) {
			// console.log('>> refresh resRefresh.status === 200');

			const data = resRefresh.data;
			localStorage.setItem('token', data.token);
			store.dispatch('auth/refreshUser', data.user).then();

			return Promise.resolve(true);
		}

		store.dispatch('auth/logout').then();
		return Promise.reject();
	};

	private getToken = () => {
		return localStorage.getItem('token');
	};

	private initializeRequestInterceptor = () => {
		this.axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
			// console.log('>> initializeRequestInterceptor:', config);
			// config.headers['Authorization'] = `Bearer ${this.getToken()}`;
			// return config;
			const token = this.getToken();
			if (token) config.headers.Authorization = `Bearer ${token}`;
			return config;
		});
	};

	private initializeResponseInterceptor = () => {
		this.axiosInstance.interceptors.response.use((response) => {
			// Return a successful response back to the calling service
			return response;
		}, async (error) => {

			// console.log('>> initializeResponseInterceptor:', error.response);

			// ToDo
			if (error.response.status >= 500) {
				store.dispatch('auth/logout', true).then();
				return Promise.reject(error);
			}

			if (error.response.status === 423) {
				if (store.state.auth.status.loggedIn) {
					store.dispatch('auth/logout').then();
				}
				return Promise.reject(error);
			}

			// if ([400,409].indexOf(error.response.status) != -1) {
			if ([409].indexOf(error.response.status) != -1) {
				return Promise.reject(error.response);
			}

			if (error.response.status === 404) {
				return Promise.reject(error.response);
			}

			if (error.response.status !== 401) {
				if (store.state.auth.status.loggedIn) {
					store.dispatch('auth/logout').then();
				}
				return Promise.reject(error);
			}

			if (error.config.url == config.apiBaseURI + 'refresh') {
				// store.dispatch('auth/logout').then();
				router.push('/login').catch((error) => {console.log(error)});

				// TokenStorage.clear();
				// router.push({ name: 'root' });
				// return new Promise((resolve, reject) => {
				// 	reject(error);
				// });
				return Promise.reject(error);
			}


			// Тут проверять, что рефреш

			if (!refreshPromise) {
				refreshPromise = this.post('refresh', {}, {
					withCredentials: true,
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				})
					.then((res) => {
						if (res?.data?.token) {
							const newToken = res.data.token;
							localStorage.setItem('token', newToken);
							return newToken;
						} else {
							localStorage.removeItem('token');
							store.dispatch('auth/logout').then();
							return Promise.reject();
						}
					})
					.catch((error) => Promise.reject(error));
			}

			return refreshPromise.then((newToken: string) => {
				refreshPromise = null;
				error.config.headers.Authorization = `Bearer ${newToken}`;
				return axios(error.config); // origin config
			});


			// // Try request again with new token
			// const resRefresh = await this.post('refresh', {}, {
			// 	withCredentials: true,
			// 	headers: {
			// 		'Accept': 'application/json',
			// 		'Content-Type': 'application/json',
			// 	},
			// });
			//
			// // console.log('>> resRefresh', resRefresh);
			// if (resRefresh.status === 200) {
			// 	// console.log('>> resRefresh.status === 200');
			//
			// 	const data = resRefresh.data;
			//
			// 	localStorage.setItem('token', data.token);
			//
			// 	// console.log('>> new token:', data.token);
			// 	// console.log('>> originalConfig:', error.config.headers.Authorization);
			//
			// 	error.config.headers.Authorization = `Bearer ${data.token}`;
			//
			// 	return axios(error.config); // origin config
			// }
			//
			// store.dispatch('auth/logout').then();
			// return Promise.reject();
		});
	};


	get(uri: string, config?: AxiosRequestConfig) {
		return this.axiosInstance.get(this.baseUrl + uri, config);
	}

	post(uri: string, data?: any, config?: AxiosRequestConfig) {
		return this.axiosInstance.post(this.baseUrl + uri, data, config);
	}

	put(uri: string, data?: any, config?: AxiosRequestConfig) {
		return this.axiosInstance.put(this.baseUrl + uri, data, config);
	}

	// request(method: any, uri: string, data: any = null) {
	// 	// if (!method) {
	// 	// 	console.error('API function call requires method argument');
	// 	// 	return;
	// 	// }
	// 	//
	// 	// if (!uri) {
	// 	// 	console.error('API function call requires uri argument');
	// 	// 	return;
	// 	// }
	//
	// 	const url = this.baseUrl + uri;
	// 	return this.axiosInstance({method, url, data});
	// }

	request(config: any) {
		return this.axiosInstance.request(config);
	}

}

// const myClassInstance = ApiService.Instance;
