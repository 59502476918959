






























import {Component, PropSync, Vue} from "vue-property-decorator";

@Component({
	components: {}
})
export default class AuthLayout extends Vue {
	@PropSync('show', {type: Boolean}) _show: boolean | undefined;
	@PropSync('text', {type: String}) _text: boolean | undefined;

	// mounted() {
	// 	console.log('>> Login mounted');
	// }
}
