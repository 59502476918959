// Common
export interface IComboboxValue {
	value: string
	text: string
	disabled?: boolean
}

// Users roles settings
interface IRoleSettings {
	id: string
	name: string
	defaultRoute: string
	comboboxDisabled?: boolean
}

const ROLE_SETTINGS: IRoleSettings[] = [
	// {id: 'user', name: 'Контрагент', defaultRoute: '/dashboard'},
	{id: 'oper', name: 'Оператор', defaultRoute: '/dashboard'},
	{id: 'pay_oper', name: 'Оператор платежей', defaultRoute: '/active-transfers'},
	{id: 'admin', name: 'Администратор', defaultRoute: '/adm_home', comboboxDisabled: true}
];

// Users roles combobox
const USER_ROLES: IComboboxValue[] = ROLE_SETTINGS.map((role: IRoleSettings) => {
	return {
		value: role.id,
		text: role.name,
		disabled: role.comboboxDisabled
	}
});

// User default routes by role
const DEFAULT_ROUTES = new Map<string, string>();
ROLE_SETTINGS.forEach(el => DEFAULT_ROUTES.set(el.id, el.defaultRoute));

// User statuses
interface IUserStatus {
	id: string
	name: string
}

const USER_STATUSES: IUserStatus[] = [
	{id: 'new', name: 'Новый'},
	{id: 'confirmed', name: 'Подтвержден'},
	{id: 'active', name: 'Активен'},
];

const USER_STATUSES_SHOW = new Map<string, string>();
USER_STATUSES.forEach(el => USER_STATUSES_SHOW.set(el.id, el.name));

//
export default {
	USER_ROLES: USER_ROLES,
	DEFAULT_ROUTES: DEFAULT_ROUTES,
	USER_STATUSES_SHOW: USER_STATUSES_SHOW,
	MIN_PASSWORD_LENGTH: 4
}
