export const formatAmount = (value: number, decimalCount = 2) => {
	try {
		return new Intl.NumberFormat('ru', {
			minimumFractionDigits: decimalCount,
			maximumFractionDigits: decimalCount
		}).format(value);
	} catch {
		() => undefined
	}

	return value;
};

export const formatAmountCurrency = (value: number, currency: string, decimalCount = 2) => {
	return `${formatAmount(value, decimalCount)} ${currency.toLocaleUpperCase()}`;
};

export const isNumber = (n: any) => {
	return !isNaN(parseFloat(n)) && isFinite(n);
};

export const formatDateYMD = (date: Date) => {
	const d = new Date(date);
	let month = '' + (d.getMonth() + 1);
	let day = '' + d.getDate();
	const year = d.getFullYear();
	if (month.length < 2) month = '0' + month;
	if (day.length < 2) day = '0' + day;
	return `${year}-${month}-${day}`;
};
