


























































































































import {Component, PropSync, Vue} from 'vue-property-decorator';
// import {IMyBank} from "@/store/main";

// agent_id: "XTL"
// created_ts: "2021-10-24T11:37:03.435Z"
// deposit_amount: 710432
// exchange_rate: 1
// ext_id: null
// fee: null
// id: 10
// incoming_amount: 710432
// incoming_currency: "RUB"
// raw_info: "{\"channel\":\"ANY\",\"code\":\"67GLEMJBZFDPSGNWMLA2\",\"amount\":710432}"
// res_info: "{\"response\":{\"amount\":71043200,\"code\":\"67GLEMJBZFDPSGNWMLA2\",\"comission\":0,\"detail\":{\"amount\":\"710432.0\",\"code\":\"67GLEMJBZFDPSGNWMLA2\",\"created_at\":null,\"currency\":\"rub\",\"id\":742845,\"used\":true,\"used_at\":\"2021-10-24T17:37:03.628+03:00\"},\"refill\":71043200,\"total_balance\":77857959.8},\"status\":201}"
// status: "CONFIRMED"
// updated_ts: "2021-10-24T11:37:04.398Z"
// wallet: "XT-LOGIC-BALANCE"

@Component({
	components: {}
})
export default class DepositCard extends Vue {
	@PropSync('show', {type: Boolean}) _show: boolean | undefined;
	// @PropSync('item') _item: IMyBank | undefined | null;
	@PropSync('item') _item: any;

	editable = false;

	DEFAULT_COLOR = 'grey lighten-1';

	closeSettingsHandler(isSave: boolean) {
		// console.log('>> closeSettingsHandler:', isSave);
		this.$emit('update:item', isSave ? this._item : null);
		this._show = false;
	}
}
