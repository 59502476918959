








import {Component, Vue} from 'vue-property-decorator';
import TransfersList from "@/components/pay-oper/TransfersList.vue";

@Component({
	components: {
		TransfersList
	}
})
export default class ActiveTransfers extends Vue {
}
