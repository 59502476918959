














	import {Component, Vue} from 'vue-property-decorator';
	import UserEvents from "@/components/settings/UserEvents.vue";
	import UserAuth from "@/components/settings/UserAuth.vue";
	import ProfileInfo from "@/components/settings/ProfileInfo.vue";

	@Component({
		components: {
			UserAuth,
			UserEvents,
      ProfileInfo
		}
	})
	export default class Setting extends Vue {
		selectedTab = -1;
		tabs = [
			{id: 'profile', label: 'Профиль'},
			{id: 'events', label: 'События'},
		];

		mounted() {
			if (this.$route.params.tab) {
				const tabIndex = this.tabs.findIndex(el => el.id === this.$route.params.tab);
				if (tabIndex !== -1) this.selectedTab = tabIndex;
			}
		}
	}
