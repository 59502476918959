





















	import {Component, Vue} from 'vue-property-decorator';
	import {namespace} from "vuex-class";
	import {IAppSnackbar} from '@/store/main';

	const mainStore = namespace("MAIN");

	@Component({
		components: {},
	})
	export default class AppSnackbar extends Vue {

		@mainStore.State("snackbar")
		private snackbar!: IAppSnackbar;

		@mainStore.Action
		private hideSnackbar!: () => Promise<void>;

		// closeHandler() {
    //   store.dispatch('MAIN/hideSnackbar').then();
		// }

		// @Watch('snackbar', {deep: true})
		// onSnackbarChanged(val: boolean, oldVal: boolean) {
		// 	console.log('>> Watch onSnackbarChanged', val);
		// }
	}
