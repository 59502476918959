































import {Component, Watch, Vue, PropSync} from 'vue-property-decorator';
import {ApiService} from '@/service/api';
import {DataTableHeader} from "vuetify";
import {formatAmount, isNumber} from '@/helper/format';
import {getStatusSettings} from "@/model/transfer";
import {getAgentSettings} from "@/model/agent";
import TransferCard from "@/components/pay-oper/TransferCard.vue";

const ITEMS_PER_PAGE_KEY = 'opt-trans-list-ipp';

@Component({
	components: {TransferCard}
})
export default class TransfersList extends Vue {
	@PropSync('open_mode', {type: String}) _open_mode: string | undefined;
	@PropSync('filters') _filters: any;

	headers: DataTableHeader[] = [
		{text: 'ID', value: 'id', sortable: false},
		{text: 'Статус', value: '_status', sortable: false},
		{text: 'Сумма', value: '_amount', sortable: false, align: 'end'},
		{text: 'Оплачено', value: '_paid_amount', sortable: false, align: 'end'},
		{text: 'Банк', value: 'card_bank', sortable: false},
		{text: 'Создан', value: '_created', sortable: false},
		{text: 'Ext ID', value: 'ext_id_in', sortable: false},
	];
	items: any[] = [];
	totalItems = 1000;
	loading = false;
	options: any = {
		itemsPerPage: 15
	};
	footerProps: any = {
		"items-per-page-options": [10, 15, 20, 30, 50]
	};

	editedItem = null;
	showItemCard = false;

	@Watch('options', {deep: true})
	onOptionChanged(val: any, oldVal: any) {
		if (val.itemsPerPage !== oldVal.itemsPerPage)
			localStorage.setItem(ITEMS_PER_PAGE_KEY, val.itemsPerPage.toString());
		this.getDataFromAPI();
	}

	@Watch('_filters', {deep: true})
	onFilterChanged(val: any, oldVal: any) {
		// console.log('>>> onFilterChanged', oldVal, val);
		this.getDataFromAPI();
	}

	mounted() {
		const ipp = localStorage.getItem(ITEMS_PER_PAGE_KEY);
		if (ipp && isNumber(ipp))
			this.options.itemsPerPage = Number(ipp);

		this.headers = this._open_mode === 'active'
			? [
				{text: 'ID', value: 'id', sortable: false},
				{text: 'Статус', value: '_status', sortable: false},
				{text: 'Сумма', value: '_amount', sortable: false, align: 'end'},
				{text: 'Банк', value: 'card_bank', sortable: false},
				{text: 'Создан', value: '_created', sortable: false},
				{text: 'Ext ID', value: 'ext_id_in', sortable: false},
			]
			: [
				{text: 'ID', value: 'id', sortable: false},
				{text: 'Статус', value: '_status', sortable: false},
				{text: 'Сумма', value: '_amount', sortable: false, align: 'end'},
				{text: 'Оплачено', value: '_paid_amount', sortable: false, align: 'end'},
				{text: 'Банк', value: 'card_bank', sortable: false},
				{text: 'Создан', value: '_created', sortable: false},
				{text: 'Ext ID', value: 'ext_id_in', sortable: false},
			];
	}

	getDataFromAPI() {
		try {
			let params: any = {};
			if (this.options.itemsPerPage !== -1)
				params = {
					limit: this.options.itemsPerPage,
					offset: (this.options.page - 1) * this.options.itemsPerPage
				};

			if (this._filters && this._filters.statuses && this._filters.statuses.length)
				params.statuses = this._filters.statuses;

			if (this._filters && this._filters.anySearch)
				params.search = this._filters.anySearch;

			this.loading = true;
			const qUrl = this._open_mode === 'active' ? 'qtr/active-transfers' : 'qtr/transfers';
			ApiService.Instance.get(qUrl, {params: params})
				.then(res => {

					this.totalItems = Number(res.data.count);

					this.items = res.data.data.map((el: any) => {
						return {
							...el,
							_created: (new Date(el.created_ts)).toLocaleString("ru"),
							_updated: el.updated_ts ? (new Date(el.updated_ts)).toLocaleString("ru") : '',
							_amount: formatAmount(el.amount, 2),
							_paid_amount: formatAmount(el.paid_amount, 2),
							_status: el.status,
							_color: this.getStatusColor(el.status)
						}
					});
				})
				.catch(err => console.warn(err))
				.finally(() => this.loading = false);
		} catch (e) {
			console.warn(e);
		}
	}

	getStatusColor(status: string) {
		const preset = getStatusSettings(status);
		return preset.color;
	}

	getAgentName(agent: string) {
		const preset = getAgentSettings(agent);
		return preset.name;
	}

	rowClickHandler(item: any) {
		// console.log('>> rowClickHandler', item);
		this.editedItem = item;
		this.showItemCard = true;
	}

	async updateItemHandler(payload: any) {
		// console.log('>> updateItemHandler:', payload);
		if (payload) this.$store.dispatch('MAIN/showSnackbarInfo', 'Временно недоступно').then();
	}

	async onTransferUpdated(payload: any) {
		this.getDataFromAPI();
	}
}
