



















	import Vue from 'vue';
	import AppSideBar from "@/view/layouts/AppSideBar.vue";
	import AppBar from "@/view/layouts/AppBar.vue";
	import AppSnackbar from "@/view/layouts/AppSnackbar.vue";
	import {Component} from "vue-property-decorator";

	@Component({
		components: {
			AppSideBar,
			AppBar,
			AppSnackbar
		}
	})
	export default class Private extends Vue {
		title = '';
		ws: WebSocket | null = null;

		mounted() {
			// console.log('>> Private mounted');
			// this.ws = new WebSocket('ws://localhost:3001');
			// this.ws.binaryType = 'arraybuffer';

      // ToDo: to ENV
			this.ws = new WebSocket(`ws://localhost:3001/ws?token=${localStorage.getItem('token')}`);

			this.ws.onopen = (e: any) => {
				// console.log('>> WS onopen:', e);
				console.log('>> WebSocket opened');
				this.ws?.send(JSON.stringify({_e: 'ping'}));
			};

			this.ws.onmessage = (e: any) => {
				// console.log('>> WS onmessage:', e.data);
				const jData = JSON.parse(e.data);
				console.log('>> WS onmessage:', jData);
			};

			this.ws.onerror = (e: any) => {
				console.log('>> WS onerror:', e);
			};

			this.ws.onclose = (e: any) => {
				console.log('>> WS onclose:', e);
			};
		}
	}

