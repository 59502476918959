








	import Vue from 'vue';
	import HelloWorld from '../../components/HelloWorld.vue';
  // import {getSidebarMenu} from '@/router/routes';

	export default Vue.extend({
		name: 'Home',

		components: {
			HelloWorld,
		},

    mounted(): void {
			// console.log('>> getSidebarMenu:', getSidebarMenu());
		}
	})
