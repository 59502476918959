












	import {Component, Watch, Vue} from 'vue-property-decorator';
	import {ApiService} from '@/service/api';
	import {DataTableHeader} from "vuetify";
	import {isNumber} from "@/helper/format";

	const ITEMS_PER_PAGE_KEY = 'opt-usr-events-ipp';

	@Component({
		components: {}
	})
	export default class UserEvents extends Vue {
		headers: DataTableHeader[] = [
			{text: 'Id', value: 'id', sortable: false},
			{text: 'Date/time', value: 'dt', sortable: false},
			{text: 'Type', value: 'type', sortable: false},
			{text: 'Description', value: 'description', sortable: false},
		];
		items: any[] = [];
		totalItems = 1000;
		loading = false;
		options: any = {
			itemsPerPage: 15
    };
		footerProps: any = {
			"items-per-page-options": [10, 15, 20, 50]
    };

		@Watch('options', {deep: true})
		onOptionChanged(val: any, oldVal: any) {
			if (val.itemsPerPage !== oldVal.itemsPerPage) localStorage.setItem(ITEMS_PER_PAGE_KEY, val.itemsPerPage.toString());
			this.getDataFromAPI();
		}

		mounted() {
			const ipp = localStorage.getItem(ITEMS_PER_PAGE_KEY);
			if (ipp && isNumber(ipp)) this.options.itemsPerPage = Number(ipp);
		}

		getDataFromAPI() {
			try {
				const params = this.options.itemsPerPage !== -1
					? {
						params: {
							limit: this.options.itemsPerPage,
							offset: (this.options.page - 1) * this.options.itemsPerPage
						}
					}
					: {};

				this.loading = true;
				ApiService.Instance.get(`users/${this.$store.state?.auth?.user?.id}/events`, params)
					.then(res => {
						this.totalItems = res.data.data[0]?.full_count ? parseInt(res.data.data[0]?.full_count) : 0;
						this.items = res.data.data.map((el: any) => {
							return {
								id: el.id,
								dt: (new Date(el.ts)).toLocaleString("ru"),
								type: el.type,
								description: el.description
							}
						});
					})
					.catch(err => console.warn(err))
					.finally(() => this.loading = false);
			} catch (e) {
				console.warn(e);
			}
		}
	}
