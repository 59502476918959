

































import {Component, Vue} from 'vue-property-decorator';

@Component({
	components: {},
})
export default class HomeStd extends Vue {

	multiLine = true;
	snackbar = false;
	text = `I'm a multi-line snackbar.`;

}
