






























































	import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
	import {ApiService} from "@/service/api";
	import {isNumber} from '@/helper/format'

	@Component({
		components: {}
	})
	export default class GarantexRefillCard extends Vue {
		@PropSync('show', {type: Boolean}) _show: boolean | undefined;

		DEFAULT_COLOR = 'grey lighten-1';
		loading = false;
		valid = true;

		code = '';
		codeRules = [
			(value: any) => !!value || 'Обязательное'
		];

		amount = '';
		amountRules = [
			(value: any) => !!value || 'Обязательное',
			(value: any) => isNumber(value) || 'Не число'
		];

		$refs!: {
			form: HTMLFormElement
		};

		refillClickHandler() {
			if (!this.$refs.form.validate()) return;
			this.processRefill();
		}

		cancelClickHandler() {
			this._show = false;
		}

		processRefill() {
			try {
				this.loading = true;
				ApiService.Instance.post(`garantex-code`, {
					code: this.code,
					amount: this.amount
				})
					.then(res => {
						// console.log('>>> ApiService post garantex-code res:', res?.data?.status);

						if (res?.data?.status === 'ok') {
							this.$store.dispatch('MAIN/showSnackbarInfo', res?.data?.description).then();
							this._show = false;
							this.code = '';
							this.amount = '';
							return;
            }

						this.$store.dispatch('MAIN/showSnackbarError', res?.data?.description ? res?.data?.description : 'Ошибка выполнения').then();
					})
					.catch(err => {
						console.warn(err);
          })
					.finally(() => {
						this.loading = false;
          });
			} catch (e) {
				console.warn(e);
			}
		}
	}
