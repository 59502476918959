










































import {Component, Inject, Vue} from 'vue-property-decorator';
import {ApiService} from '@/service/api';
import {IUser} from '@/model';
import UserCard from '@/components/admin/UserCard.vue';
import UserInvite from '@/components/admin/UserInvite.vue';
import {DataTableHeader} from "vuetify";

@Component({
	components: {
		UserCard,
		UserInvite
	}
})
export default class UsersList extends Vue {
	@Inject() readonly USER_STATUSES_SHOW!: Map<string, string>;

	users: IUser[] = [];
	search: string | null = '';
	headers: DataTableHeader[] = [
		{text: 'Id', value: 'id'},
		{text: 'Email', value: 'email'},
		{text: 'Имя', value: 'name'},
		{text: 'Статус', value: 'status_'},
	];
	cardOpened = false;
	openedItem: IUser = {};
	inviteOpened = false;

	mounted() {
		this.fetchUsersList();
	}

	fetchUsersList() {
		try {
			ApiService.Instance.get("users")
				.then(res => {
					// this.users = [...res.data];
					this.users = res.data.map((el: IUser) => ({
						...el,
						status_: el.status ? this.USER_STATUSES_SHOW.get(el.status) : 'n/a'
					}));
				})
				.catch(err => console.warn(err));
		} catch (e) {
			console.warn(e);
		}
	}

	rowClickHandler(item: IUser) {
		this.openedItem = {...item};
		this.cardOpened = true;
	}

	// @Watch('cardOpened')
	// onCardOpenedChanged(val: boolean, oldVal: boolean) {
	// 	console.log('>> cardOpened watch', val, oldVal);
	// }

	changeUserHandler(item: IUser) {
		try {
			ApiService.Instance.put(`users/${item.id}`, item)
				.then(() => this.fetchUsersList())
				.catch(err => console.warn(err));
		} catch (e) {
			console.warn(e);
		}
	}

	inviteUserHandler(user: IUser) {
		this.fetchUsersList();
	}
}
