import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import config from '@/config';
import {getAvailableRoutes} from './routes';
import {ApiService} from '@/service/api';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes: getAvailableRoutes()
});

// check same route
router.beforeEach((to, from, next) => {
	if (to.path === from.path) return;
	next();
});


// check user auth
router.beforeEach(async (to, from, next) => {
	if (to.matched.some(el => el.meta.requireAuth) &&
		(!router.app.$store?.state?.auth?.status?.loggedIn || !localStorage.getItem('token'))
	) {

		// console.log('>>> Routes beforeEach NEED REFRESH');

		const refreshRes = await ApiService.Instance.tryToRefreshToken();
		if (refreshRes) {
			next();
			return;
		}

		next({path: '/login', query: {redirect: to.fullPath}});
		return;
	}

	next();
});

// check user permission
router.beforeEach((to, from, next) => {
	// if 2fa not setted, redirect to 2fa setup page
	// if (store.state?.auth?.user?.status === 'new' && to.path != '/setup2fa') {
	if (store.state?.auth?.user?.enable_2fa === false && to.path != '/setup2fa') {
		next({path: '/setup2fa'});
		return;
	}

	if (to.matched.some(el => el.meta.perm)) {
		if (to.meta?.perm) {
			if (!store.state?.auth?.user?.permission || !Array.isArray(store.state?.auth?.user?.permission)) {
				console.warn("Can't access. Undefined state");
				// ToDo?: don't have permission message
				return;
			}

			const permRequire = to.matched.find(el => el.meta.perm)?.meta?.perm;
			if (!store.state?.auth?.user?.permission.some((el: string) => el === permRequire)) {
				console.warn("Can't access. Permission require", permRequire);
				// ToDo?: don't have permission message
				return;
			}
		}
	}

	next();
});

const DEFAULT_TITLE = 'Web VUE';
// router.afterEach((to, from) => {
router.afterEach((to) => {
	// Use next tick to handle router history correctly
	// see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
	Vue.nextTick(() => {
		const title = to?.meta?.title || to?.name || DEFAULT_TITLE;
		//document.title = title;
		document.title = `${config.name === 'local' ? '[LOCAL] ' : ''}KTX-App ¦ ${title}`;
		// store.dispatch('MAIN/updateTitle', title).then();
		store.commit('MAIN/SET_TITLE', title);
	});
});


export default router;
