




















	import {Component, Vue} from 'vue-property-decorator';
	import DepositsList from "@/components/operator/DepositsList.vue";
	import GarantexRefillCard from '@/components/operator/GarantexRefillCard.vue';

	@Component({
		components: {
			DepositsList,
			GarantexRefillCard
    }
	})
	export default class Deposits extends Vue {
		showGarantexRefillCard = false;

		refillClickHandler() {
			this.showGarantexRefillCard = true;
		}
	}
