



























	import {Component, Vue, Watch} from "vue-property-decorator";
	import {namespace} from "vuex-class";
	// import ToolbarBalance from "@/components/agent/ToolbarBalance.vue";

	const mainStore = namespace("MAIN");
	const authStore = namespace("auth");

	@Component({
		components: {
			// ToolbarBalance
    }
	})
	export default class AppBar extends Vue {

		notificationCount = 0;

		@mainStore.State("title")
		private title!: string;

		@authStore.Action("logout")
		private logout!: () => Promise<void>;

		showNotification() {
			console.log('>> showNotification:', this.notificationCount);
		}

		// @Watch('title', {deep: true})
		// onTitleChanged(val: boolean, oldVal: boolean) {
		// 	console.log('>> Watch onTitleChanged', val);
		// }
	}
