import Vue from "vue";
import Vuex from "vuex";
import {GetterTree, MutationTree, ActionTree} from "vuex";
import MainModule from "./main";
import AuthModule from "./auth";

Vue.use(Vuex);

class State {
	MAIN: any;
	auth: any;
}

const getters = <GetterTree<State, any>>{};

const mutations = <MutationTree<State>>{
	// setUserId(state, payload) { 		state.userId = payload;	}
};

const actions = <ActionTree<State, any>>{
	// fetchUserId(store) { 	}
};

export default new Vuex.Store({
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters,
	modules: {
		MAIN: MainModule,
		auth: AuthModule
	}
})
