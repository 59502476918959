



















































































import Vue from 'vue';
// import {getSidebarMenu} from '@/router/routes';

export default Vue.extend({
	name: 'Test1',

	components: {},

	data: () => ({
		loading: false,
		selection: 1,
	}),

	methods: {
		reserve(): void {
			this.loading = true;
			setTimeout(() => (
				this.loading = false
			), 2000);
		},
	},

	mounted(): void {
		// console.log('>> getSidebarMenu:', getSidebarMenu());
	}
})
