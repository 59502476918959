






























































































































import {Component, Inject, Vue, Watch} from 'vue-property-decorator';
import {ApiService} from '@/service/api';
import {DataTableHeader} from "vuetify";
import {formatAmount, formatDateYMD} from "@/helper/format";
import {getAgentSettings} from "@/model/agent";

@Component({
	components: {}
})
export default class AdmReports extends Vue {

	headers: DataTableHeader[] = [
		{text: 'Дата', value: 'date'},
		{text: 'XT-Logic', value: 'XTL', align: 'end'},
		{text: 'Alikassa', value: 'ALK', align: 'end'},
		{text: 'QTrade', value: 'QTR', align: 'end'},
		{text: 'BG-Panel', value: 'PNL', align: 'end'},
		{text: 'Всего', value: 'total', align: 'end'},
	];

	loading = false;

	tData: any[] = [];
	tSumXTL: any = '';
	tSumALK: any = '';
	tSumQTR: any = '';
	tSumPNL: any = '';
	tSumXTLpr: any = '';
	tSumALKpr: any = '';
	tSumQTRpr: any = '';
	tSumPNLpr: any = '';
	tSumTotal: any = '';

	periodStart = '';
	periodEnd = '';

	showStart = false;
	showEnd = false;

	$refs!: {
		wrapStart: HTMLFormElement,
		wrapEnd: HTMLFormElement
	};

	@Watch('periodStart')
	onPeriodStartChanged(val: any, oldVal: any) {
		if (oldVal !== val)
			this.showStart = false;
	}

	@Watch('periodEnd')
	onPeriodEndChanged(val: any, oldVal: any) {
		if (oldVal !== val)
			this.showEnd = false;
	}

	mounted() {
		const date = new Date();
		const startMonth = new Date(date.getFullYear(), date.getMonth(), 1);
		this.periodStart = formatDateYMD(startMonth);
		this.periodEnd = formatDateYMD(new Date());
		this.fetchSettings();
	}

	parseData(data: any) {
		let resObj: any = {};

		data.forEach((item: any) => {
			let dateObj = resObj[item.date] ? resObj[item.date] : {};
			dateObj[item['agent']] = item['sum'];
			resObj[item.date] = dateObj;
		});

		let resData = [];
		for (const key in resObj) {
			resData.push({
				date: key,
				...resObj[key]
			})
		}

		let tSumXTL = 0;
		let tSumALK = 0;
		let tSumQTR = 0;
		let tSumPNL = 0;
		let tSumTotal = 0;
		this.tData = resData.map((item: any) => {
			const sumXTL = item.XTL ? Number(item.XTL) : 0;
			const sumALK = item.ALK ? Number(item.ALK) : 0;
			const sumQTR = item.QTR ? Number(item.QTR) : 0;
			const sumPNL = item.PNL ? Number(item.PNL) : 0;
			tSumXTL += sumXTL;
			tSumALK += sumALK;
			tSumQTR += sumQTR;
			tSumPNL += sumPNL;
			const pRowTotal = sumXTL + sumALK + sumQTR + sumPNL;
			tSumTotal += pRowTotal;
			return {
				date: (new Date(item.date)).toLocaleString("ru").substring(0, 10),
				XTL: item.XTL ? formatAmount(item.XTL, 2) : null,
				ALK: item.ALK ? formatAmount(item.ALK, 2) : null,
				QTR: item.QTR ? formatAmount(item.QTR, 2) : null,
				PNL: item.PNL ? formatAmount(item.PNL, 2) : null,
				XTL_pr: item.XTL ? formatAmount(item.XTL / pRowTotal * 100, 0) : null,
				ALK_pr: item.ALK ? formatAmount(item.ALK / pRowTotal * 100, 0) : null,
				QTR_pr: item.QTR ? formatAmount(item.QTR / pRowTotal * 100, 0) : null,
				PNL_pr: item.PNL ? formatAmount(item.PNL / pRowTotal * 100, 0) : null,
				total: formatAmount(pRowTotal, 2)
			}
		});
		this.tSumXTL = tSumXTL ? formatAmount(tSumXTL, 2) : null;
		this.tSumALK = tSumALK ? formatAmount(tSumALK, 2) : null;
		this.tSumQTR = tSumQTR ? formatAmount(tSumQTR, 2) : null;
		this.tSumPNL = tSumPNL ? formatAmount(tSumPNL, 2) : null;
		this.tSumTotal = tSumTotal ? formatAmount(tSumTotal, 2) : null;

		const repTotal = tSumXTL + tSumALK + tSumQTR + tSumPNL;
		this.tSumXTLpr = tSumXTL ? formatAmount(tSumXTL / repTotal * 100, 0) : null;
		this.tSumALKpr = tSumALK ? formatAmount(tSumALK / repTotal * 100, 0) : null;
		this.tSumQTRpr = tSumQTR ? formatAmount(tSumQTR / repTotal * 100, 0) : null;
		this.tSumPNLpr = tSumPNL ? formatAmount(tSumPNL / repTotal * 100, 0) : null;
	}

	fetchSettings() {
		try {
			let params = {
				params: {
					start: this.periodStart,
					end: this.periodEnd
				}
			};

			this.loading = true;
			ApiService.Instance.get(`reports/2`, params)
				.then(res => {
					this.parseData(res.data);
				})
				.catch(err => console.warn(err))
				.finally(() => this.loading = false);
		} catch (e) {
			console.warn(e);
		}
	}

	applyReportParam() {
		this.fetchSettings();
	}

	getAgentName(agent: string) {
		const preset = getAgentSettings(agent);
		return preset.name;
	}
}
