var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"d-flex"},[_c('div',{staticStyle:{"margin-right":"24px"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Начало","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.periodStart),callback:function ($$v) {_vm.periodStart=$$v},expression:"periodStart"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showStart),callback:function ($$v) {_vm.showStart=$$v},expression:"showStart"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.periodStart),callback:function ($$v) {_vm.periodStart=$$v},expression:"periodStart"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showStart = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('div',{staticStyle:{"margin-right":"24px"}},[_c('v-menu',{ref:"wrapEnd",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Конец","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.periodEnd),callback:function ($$v) {_vm.periodEnd=$$v},expression:"periodEnd"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.showEnd),callback:function ($$v) {_vm.showEnd=$$v},expression:"showEnd"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.periodEnd),callback:function ($$v) {_vm.periodEnd=$$v},expression:"periodEnd"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.showEnd = false}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('div',[_c('v-btn',{staticClass:"ma-2",on:{"click":_vm.applyReportParam}},[_vm._v(" Применить ")])],1)]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.tData,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.XTL",fn:function(ref){
var item = ref.item;
return [(item.XTL_pr)?_c('span',[_vm._v("("+_vm._s(item.XTL_pr)+"%)")]):_vm._e(),_vm._v(" "+_vm._s(item.XTL)+" ")]}},{key:"item.ALK",fn:function(ref){
var item = ref.item;
return [(item.ALK_pr)?_c('span',[_vm._v("("+_vm._s(item.ALK_pr)+"%)")]):_vm._e(),_vm._v(" "+_vm._s(item.ALK)+" ")]}},{key:"item.QTR",fn:function(ref){
var item = ref.item;
return [(item.QTR_pr)?_c('span',[_vm._v("("+_vm._s(item.QTR_pr)+"%)")]):_vm._e(),_vm._v(" "+_vm._s(item.QTR)+" ")]}},{key:"item.PNL",fn:function(ref){
var item = ref.item;
return [(item.PNL_pr)?_c('span',[_vm._v("("+_vm._s(item.PNL_pr)+"%)")]):_vm._e(),_vm._v(" "+_vm._s(item.PNL)+" ")]}}])},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"table-footer"},[_c('th',[_vm._v("Итого")]),_c('th',{staticClass:"text-end"},[(_vm.tSumXTLpr)?_c('span',[_vm._v("("+_vm._s(_vm.tSumXTLpr)+"%)")]):_vm._e(),_vm._v(" "+_vm._s(_vm.tSumXTL)+" ")]),_c('th',{staticClass:"text-end"},[(_vm.tSumALKpr)?_c('span',[_vm._v("("+_vm._s(_vm.tSumALKpr)+"%)")]):_vm._e(),_vm._v(" "+_vm._s(_vm.tSumALK)+" ")]),_c('th',{staticClass:"text-end"},[(_vm.tSumQTRpr)?_c('span',[_vm._v("("+_vm._s(_vm.tSumQTRpr)+"%)")]):_vm._e(),_vm._v(" "+_vm._s(_vm.tSumQTR)+" ")]),_c('th',{staticClass:"text-end"},[(_vm.tSumPNLpr)?_c('span',[_vm._v("("+_vm._s(_vm.tSumPNLpr)+"%)")]):_vm._e(),_vm._v(" "+_vm._s(_vm.tSumPNL)+" ")]),_c('th',{staticClass:"text-end"},[_vm._v(_vm._s(_vm.tSumTotal))])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }