





















































































































































	import {Component, Inject, PropSync, Vue, Watch} from 'vue-property-decorator';
	import {IUser} from '@/model';
	import {ApiService} from "@/service/api";

	@Component({})
	export default class UserCard extends Vue {
		@PropSync('dialog', {type: Boolean}) _dialog: boolean | undefined;
		@PropSync('item') _item: IUser | undefined;

		@Inject() readonly USER_ROLES!: any[];

		actions() {
			return [
				{label: this._item?.blocked ? 'Разблокировать' : 'Заблокировать', action: this.block},
				{label: 'Сброс 2ФА', action: this.reset2fa, disabled: !this._item?.enabled2fa},
				{label: 'Сброс пароля', action: this.resetPassword},
			];
    }

		@Watch('item')
		onItemChanged(val: boolean, oldVal: boolean) {
			console.log('>> UserCard watch item:', val, oldVal);
		}

		saveHandler() {
			// ToDo: check mandatory fiels and roles (need at least one)
			this.$emit('update:item', this._item);
			this._dialog = false;
		}

		reset2fa() {
			if (!this._item?.enabled2fa)
				return;

			try {
				ApiService.Instance.post(`users/reset2fa`, {id: this._item?.id})
					.then((res) => {
						this.$store.dispatch('MAIN/showSnackbarInfo', res.data).then();
						if (this._item)
							this._item.enabled2fa = false;
					})
					.catch(err => {
						console.warn(err);
						this.$store.dispatch('MAIN/showSnackbarError', 'Ошибка выполнения').then();
					});
			} catch (e) {
				console.warn(e);
			}
		}

		resetPassword() {
			try {
				ApiService.Instance.post(`users/reset-password`, {id: this._item?.id})
					.then((res) => {
						this.$store.dispatch('MAIN/showSnackbarInfo', res.data).then();
					})
					.catch(err => {
						console.warn(err);
						this.$store.dispatch('MAIN/showSnackbarError', 'Ошибка выполнения').then();
					});
			} catch (e) {
				console.warn(e);
			}
		}

		block() {
			try {
				ApiService.Instance.post(`users/block`, {id: this._item?.id, block: !this._item?.blocked})
					.then((res) => {
						this.$store.dispatch('MAIN/showSnackbarInfo', res.data).then();
						if (this._item)
							this._item.blocked = !this._item.blocked;
					})
					.catch(err => {
						console.warn(err);
						this.$store.dispatch('MAIN/showSnackbarError', 'Ошибка выполнения').then();
					});
			} catch (e) {
				console.warn(e);
			}
		}
	}
