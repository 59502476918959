



















































































































































































































































import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import {getSidebarMenu} from "@/router/routes";
import {Getter} from 'vuex-class';
import {ApiService} from "@/service/api";
import store from "@/store";
import {getStatusSettings} from "@/model/transfer";
import TransferStatusesListModal from "@/components/operator/TransferStatusesListModal.vue";

@Component({
	components: {
		TransferStatusesListModal
	}
})
export default class TransferCard extends Vue {
	@PropSync('show', {type: Boolean}) _show: boolean | undefined;
	@PropSync('item') _item: any;

	@Getter('getHavePermission', {namespace: 'auth'})
	getHavePermission!: (permission: string) => boolean;

	// DEFAULT_COLOR = 'grey lighten-1';
	editable = false;
	loading = false;
	enableExtraMenu = false;
	paidAmount = '';
	headerColor = 'grey lighten-1';
	showConfirm = false;
	showStatusesCard = false;

	extraActions() {
		return [
			{label: 'История статусов', action: this.showStatusHistory},
		];
	}

	@Watch('_item')
	onItemChanged(val: any, oldVal: any) {
		this.editable = val ? !['PAID', 'CANCELED'].includes(val.status) : false;
		this.paidAmount = val.paid_amount ? val.paid_amount : '';
		this.headerColor = val._color;
	}

	mounted() {
		this.enableExtraMenu = this.getHavePermission('transfer_management');
	}

	closeSettingsHandler(isSave: boolean) {
		// console.log('>> closeSettingsHandler:', isSave);
		this.$emit('update:item', isSave ? this._item : null);
		this._show = false;
	}

	paidAmountShow() {
		return this._item?.status && !['CREATED', 'CANCELED'].includes(this._item.status);
	}

	paidAmountReadonly() {
		return this._item?.status && this._item.status !== 'PROCESS';
	}

	toStatusProcessShow() {
		return this.editable && this._item.status === 'CREATED';
	}

	toStatusPaidShow() {
		return this.editable && this._item.status === 'PROCESS';
	}

	toStatusCancelShow() {
		return this.editable && this._item.status === 'PROCESS';
	}

	toStatusProcess() {
		this.changeTransferStatus('PROCESS');
	}

	toStatusPaid() {
		try {
			if (!this.paidAmount) {
				this.showSnackbar('Требуется указать оплаченную сумму');
				return;
			}

			if (this.paidAmount !== parseFloat(this.paidAmount).toString()) {
				this.showSnackbar('Оплаченная сумма не корректна');
				return;
			}

			if (Number(this.paidAmount) < Number(this._item.amount)) {
				this.showSnackbar('Оплаченная сумма меньше исходной');
				return;
			}

			this.changeTransferStatus('PAID', parseFloat(this.paidAmount));
		} catch (e) {
			console.error(e);
		}
	}

	async toStatusCancel() {
		await this.changeTransferStatus('CANCELED');
		this.showConfirm = false;
	}

	async processAction1() {
		try {
			console.log('>>> processAction1');
			// ApiService.Instance.post("transfers/ktx-sent", {transfer_id: this._item.id})
			// 	.then(res => {
			// 		if (res?.data?.message) this.showSnackbar(res?.data?.message);
			// 	})
			// 	.catch(err => {
			// 		console.warn(err);
			// 		if (err?.data?.message) this.showSnackbar(err?.data?.message, 'error');
			// 	});
		} catch (e) {
			console.warn(e);
		}
	}

	async changeTransferStatus(newStatus: string, paidAmount: number | null = null) {
		// console.log('>>> changeTransferStatus', newStatus);

		let errMessage = 'Ошибка отправки запроса';
		try {
			this.loading = true;
			let qParam: any = {
				transfer_id: this._item.id,
				new_status: newStatus
			};
			if (this.paidAmount)
				qParam.paid_amount = paidAmount;

			const qRes = await ApiService.Instance.post(`qtr/transfer-status`, qParam);

			if (qRes.status === 200) {
				this.$emit('updated');
				this._item.status = newStatus;
				this.headerColor = this.getStatusColor(newStatus);
				this.showSnackbar('Статус обновлен');
				return;
			}

			errMessage = qRes.data?.message ? qRes.data.message : qRes.data;
		} catch (e) {
			console.warn('>>> UserInvite processInviteUser catch:', e.status, e.data);
			errMessage = e.data?.message ? e.data?.message : e.data;
		} finally {
			this.loading = false;
		}

		this.showSnackbar(errMessage, 'error');
		return;
	}

	showSnackbar(message: string, type = '') {
		store.dispatch(type === 'error' ? 'MAIN/showSnackbarError' : 'MAIN/showSnackbarInfo', message).then();
	}

	copyToClipboard(value: any) {
		navigator.clipboard.writeText(value);
		this.showSnackbar(`${value} скопировано в буфер обмена`);
	}

	getStatusColor(status: string) {
		const preset = getStatusSettings(status);
		return preset.color;
	}

	showStatusHistory() {
		this.showStatusesCard = true;
	}
}
