



































































import {Component, Inject, Vue} from "vue-property-decorator";
import AuthLayout from "@/view/auth/AuthLayout.vue";
import router from "@/router";
import config from '@/config';

@Component({
	components: {
		AuthLayout
	}
})
export default class Login extends Vue {
	@Inject() readonly MIN_PASSWORD_LENGTH!: number;
	@Inject() readonly DEFAULT_ROUTES!: Map<string, string>;

	loading = false;
	name = '';
	email = ''; // super@mail.ru
	password = ''; // 12345
	code = '';
	showPassword = false;
	valid = false;
	snackbarShow = false;
	snackbarText = '';
	step = 'login';

	rules = {
		required: (value: string) => !!value || 'Обязательное',
		email: (value: string) => {
			const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return pattern.test(value) || 'Некорректный email'
		},
		min: (v: string) => v.length >= this.MIN_PASSWORD_LENGTH || `Минимум ${this.MIN_PASSWORD_LENGTH} символов`
	};

	mounted() {
		if (config.clientDefaults?.login) this.email = config.clientDefaults?.login;
		if (config.clientDefaults?.password) this.password = config.clientDefaults?.password;
	}

	$refs!: {
		email: HTMLFormElement,
		password: HTMLFormElement,
		code: HTMLFormElement,
	};

	submit() {
		if (this.step === 'login') {
			this.$refs.email.validate(true);
			this.$refs.password.validate(true);

			Vue.nextTick(async () => {
				if (this.valid) this.processLogin();
			});
		}

		if (this.step === 'code') {
			this.$refs.code.validate(true);

			Vue.nextTick(async () => {
				if (this.valid) this.processLoginConfirm();
			});
		}
	}

	processLogin() {

		// console.log('>> processLogin START');

		this.loading = true;
		this.$store.dispatch("auth/login", {
			login: this.email,
			password: this.password
		}).then(
			(res) => {
				// console.log('>> processLogin res:', res);

				if (res.action === 'register_otp') {
					router.push('/setup2fa').then();
					return;
				}

				if (res.action === 'confirm_otp') {
					this.step = 'code';
					return;
				}

				if (res.token && res.user) {
					this.$router.push(this.getRoute());
				}
			},
			(error) => {
				let errorMessage = 'Error. Try again later';
				if (error.response?.data?.message) errorMessage = error.response?.data?.message;
				this.snackbarText = errorMessage;
				this.snackbarShow = true;
			}
		).finally(() => this.loading = false);
	}

	processLoginConfirm() {

		// console.log('>> processLoginConfirm START');

		this.loading = true;
		this.$store.dispatch("auth/loginConfirm", this.code)
			.then(
				(res) => {
					// console.log('>> processLoginConfirm res:', res);

					if (res.token && res.user) {
						localStorage.setItem('token', res.token);
						this.$router.push(this.getRoute());
					}
				},
				(error) => {
					let errorMessage = 'Error. Try again later';
					if (error.response?.data?.message) errorMessage = error.response?.data?.message;
					this.snackbarText = errorMessage;
					this.snackbarShow = true;
				}
			).finally(() => this.loading = false);
	}

	getRoute(): string {
		// console.log('>> Login router.currentRoute:', this.$router.currentRoute);

		// ToDo get correct path for new user

		// console.log('>> Login user status:', this.$store.state.auth.user.status);

		// if (this.$store.state.auth.user.status === 'new') return '/setup2fa';
		if (this.$store.state.auth.user.enable_2fa === false) return '/setup2fa';

		// ToDo: добавить процедуру проверки, что сохраненный путь существует, если нет, то редиректить на дефолтный для роли
		if (this.$store.state.auth.user.default_route) return this.$store.state.auth.user.default_route;

		return (this.$route?.query?.redirect ? this.$route.query.redirect : this.DEFAULT_ROUTES.get(this.$store.state.auth.user.base_role)) as string;
	}
}
