















import {Component, Vue} from 'vue-property-decorator';

@Component({
	components: {}
})
export default class ProfileInfo extends Vue {
	wallet = '';

	getLogin() {
		return this.$store.state.auth?.user?.email;
	}
}
