interface IAgentStatus {
	[elem: string]: {
		name: string,
		color?: string
	}
}

export const AGENT_STATUS: IAgentStatus = {
	KTX: {name: 'Kryptex', color: ''},
	XTL: {name: 'XT-Logic', color: ''},
	ALK: {name: 'Alikassa', color: ''},
	QTR: {name: 'QTrade', color: ''},
	PNL: {name: 'BG-Panel', color: ''},
};

export const getAgentSettings = (status: string) => {
	const preset = AGENT_STATUS[status];
	if (preset)
		return preset;
	return {name: 'N/A', color: ''};
};
