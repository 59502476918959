import {GetterTree, MutationTree, ActionTree, Getter} from "vuex";

export interface IAppSnackbar {
	text?: string
	color?: string
	show?: boolean
}

export interface IMainState {
	title: string
	snackbar: IAppSnackbar | null;
}

class State implements IMainState {
	title: '';
	snackbar: {
		text: '',
		color: 'blue',
		show: false
	};

	constructor() {
		this.title = '';

		this.snackbar = {
			text: '',
			color: 'blue',
			show: false
		};
	}
}

const actions = <ActionTree<State, any>>{
	showSnackbar({commit}, snackbar: IAppSnackbar): void {
		commit('SET_SNACKBAR', snackbar);
	},
	showSnackbarError({commit}, text: string): void {
		commit('SET_SNACKBAR', {
			text: text,
			color: 'red',
			show: true
		});
	},
	showSnackbarInfo({commit}, text: string): void {
		commit('SET_SNACKBAR', {
			text: text,
			color: 'blue',
			show: true
		});
	},
	hideSnackbar({commit}): void {
		commit('SET_SNACKBAR', {
			text: '',
			color: '',
			show: false
		});
	}
};

const mutations = <MutationTree<State>>{
	SET_TITLE(state: IMainState, title: string) {
		state.title = title;
	},
	SET_SNACKBAR(state: IMainState, snackbar: IAppSnackbar) {
		state.snackbar = {...snackbar};
	}
};

const getters = <GetterTree<State, any>>{
	title(state: IMainState) {
		return state.title;
	},
};

const MainModule = {
	namespaced: true,
	state: new State(),
	mutations: mutations,
	actions: actions,
	getters: getters
};

export default MainModule;
