
















	import {Component, Inject, Vue} from 'vue-property-decorator';
	import router from "@/router";
	import AppSettings from "@/view/admin/AppSettings.vue";
	import OrderAllocationSettings from "@/view/admin/OrderAllocationSettings.vue";

	@Component({
		components: {
			AppSettings,
			OrderAllocationSettings
    }
	})
	export default class AdmSettings extends Vue {
		selectedTab = -1;
		tabs: any[] = [
			{id: 'service', label: 'Сервис'},
			{id: 'order-allocation', label: 'Распределения ордеров'},
    ];

		// mounted() {}

	}
