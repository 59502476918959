























































































import {Component, Vue} from "vue-property-decorator";
import {INavListItem} from '@/model';
import {getSidebarMenu} from "@/router/routes";
import store from '@/store';

@Component({
	components: {}
})
export default class AppSideBar extends Vue {
	drawer = true;
	mini = false;
	selectedNavItem = "/";
	navItems: INavListItem[] = [];

	get rolesCount(): number {
		return store.state?.auth?.user?.roles?.length || 1;
	}

	toggleMini() {
		localStorage.setItem('app_sidebar-mini', !this.mini ? '1' : '0');
		this.mini = !this.mini;
	}

	mounted(): void {
		const savedMini = localStorage.getItem('app_sidebar-mini');
		if (savedMini !== null) this.mini = savedMini === '1';

		this.navItems = getSidebarMenu();
	}

	getLogin() {
		// console.log(this.$store.state.auth.user);
		return this.$store.state.auth?.user?.email;
	}

	// get navItems(): INavListItem[] {
	// 	let res: INavListItem[] = [];
	// 	res.push();
	// 	res.push({title: "Home", icon: "mdi-home-account", link: "/"});
	// 	res.push({title: "Home Another", icon: "mdi-home-alert", link: "/h"});
	// 	res.push({title: "Test1", icon: "mdi-home-alert", link: "/test1"});
	// 	res.push({title: "Users", icon: "mdi-account-group-outline", link: "/users"});
	// 	res.push({title: "About", icon: "mdi-information-outline", link: "/about"});
	// 	res.push({title: "Users Adm", icon: "mdi-account-group-outline", link: "/adm_users_list", divider: true, subheader: "Admin"});
	// 	return res;
	// }
}
