































	import {Component, Watch, Vue} from 'vue-property-decorator';
	import {ApiService} from '@/service/api';
	import {DataTableHeader} from "vuetify";
	import {formatAmount, isNumber} from '@/helper/format';
	import {getStatusSettings} from "@/model/deposit";
	import {getAgentSettings} from "@/model/agent";
	import DepositCard from "@/components/operator/DepositCard.vue";

	const ITEMS_PER_PAGE_KEY = 'opt-dep-list-ipp';

	@Component({
		components: {DepositCard}
	})
	export default class TransfersList extends Vue {
		headers: DataTableHeader[] = [
			{text: 'ID', value: 'id', sortable: false},
			{text: 'Статус', value: '_status', sortable: false},
			{text: 'Сумма', value: '_amount', sortable: false, align: 'end'},
			{text: 'Комиссия', value: '_fee', sortable: false, align: 'end'},
			{text: 'Создан', value: '_created', sortable: false},
			{text: 'Агент', value: '_agent', sortable: false},
		];
		items: any[] = [];
		totalItems = 1000;
		loading = false;
		options: any = {
			itemsPerPage: 15
		};
		footerProps: any = {
			"items-per-page-options": [10, 15, 20, 50]
		};

		editedItem = null;
		showItemCard = false;

		@Watch('options', {deep: true})
		onOptionChanged(val: any, oldVal: any) {
			if (val.itemsPerPage !== oldVal.itemsPerPage) localStorage.setItem(ITEMS_PER_PAGE_KEY, val.itemsPerPage.toString());
			this.getDataFromAPI();
		}

		mounted() {
			const ipp = localStorage.getItem(ITEMS_PER_PAGE_KEY);
			if (ipp && isNumber(ipp)) this.options.itemsPerPage = Number(ipp);
		}

		getDataFromAPI() {
			try {
				const params = this.options.itemsPerPage !== -1
					? {
						params: {
							limit: this.options.itemsPerPage,
							offset: (this.options.page - 1) * this.options.itemsPerPage
						}
					}
					: {};

				this.loading = true;
				ApiService.Instance.get(`deposit`, params)
					.then(res => {

						// this.totalItems = res.data.data[0]?.full_count ? parseInt(res.data.data[0]?.full_count) : 0;
						this.totalItems = Number(res.data.count);

						this.items = res.data.data.map((el: any) => {
							return {
								...el,
								_created: (new Date(el.created_ts)).toLocaleString("ru"),
								_updated: el.updated_ts ? (new Date(el.updated_ts)).toLocaleString("ru"): '',
								_amount: formatAmount(el.deposit_amount, 2),
								_incoming_amount: formatAmount(el.incoming_amount, 2),
								_fee: el.fee ? formatAmount(el.fee, 2) : '-',
								_agent: this.getAgentName(el.agent_id),
								_status: el.status,
                _color: this.getStatusColor(el.status)
							}
						});
					})
					.catch(err => console.warn(err))
					.finally(() => this.loading = false);
			} catch (e) {
				console.warn(e);
			}
		}

		getStatusColor(status: string) {
			const preset = getStatusSettings(status);
			return preset.color;
		}

		getAgentName(agent: string) {
			const preset = getAgentSettings(agent);
			return preset.name;
    }

		rowClickHandler(item: any) {
			// console.log('>> rowClickHandler', item);
			this.editedItem = item;
			this.showItemCard = true;
		}

		async updateItemHandler(payload: any) {

			// console.log('>> updateItemHandler:', payload);

		}
	}
