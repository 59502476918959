































import {Component, Watch, Vue} from 'vue-property-decorator';
import {ApiService} from '@/service/api';
import {DataTableHeader} from "vuetify";
import {formatAmount, isNumber} from '@/helper/format';
import {getStatusSettings} from "@/model/transfer";
import TransferCard from "@/components/operator/TransferCard.vue";
import {getAgentSettings} from "@/model/agent";

const ITEMS_PER_PAGE_KEY = 'opt-dash-trans-ipp';

@Component({
	components: {TransferCard}
})
export default class DashboardTransfers extends Vue {
	headers: DataTableHeader[] = [
		{text: 'ID', value: 'id', sortable: false},
		{text: 'Статус', value: '_status', sortable: false},
		{text: 'Сумма', value: '_amount', sortable: false, align: 'end'},
		{text: 'Банк', value: 'card_bank', sortable: false},
		{text: 'Создан', value: '_created', sortable: false},
		{text: 'Агент', value: '_agent', sortable: false},
		{text: 'ID in', value: 'ext_id_in', sortable: false},
		{text: 'Курсы (Профит $)', value: '_course', sortable: false},
	];
	items: any[] = [];
	totalItems = 1000;
	loading = false;
	options: any = {
		itemsPerPage: 10
	};
	footerProps: any = {
		"items-per-page-options": [10, 15, 20, 50]
	};

	editedItem = null;
	showItemCard = false;

	@Watch('options', {deep: true})
	onOptionChanged(val: any, oldVal: any) {
		if (val.itemsPerPage !== oldVal.itemsPerPage) localStorage.setItem(ITEMS_PER_PAGE_KEY, val.itemsPerPage.toString());
		this.getDataFromAPI();
	}

	mounted() {
		const ipp = localStorage.getItem(ITEMS_PER_PAGE_KEY);
		if (ipp && isNumber(ipp)) this.options.itemsPerPage = Number(ipp);
	}

	getDataFromAPI() {
		try {
			const params = this.options.itemsPerPage !== -1
				? {
					params: {
						limit: this.options.itemsPerPage,
						offset: (this.options.page - 1) * this.options.itemsPerPage
					}
				}
				: {};

			this.loading = true;
			ApiService.Instance.get(`transfers`, params)
				.then(res => {
					this.totalItems = Number(res.data.count);

					this.items = res.data.data.map((el: any) => {
						const buy_rate = el.exchange_rate;
						const sell_rate = el.paid_amount && el.amount ? el.amount / el.paid_amount : null;
						let profit = null;
						if (el.paid_currency === 'USD')
							profit = sell_rate ? (el.amount - (el.paid_amount * buy_rate)) / sell_rate : null;

						return {
							...el,
							_created: (new Date(el.created_ts)).toLocaleString("ru"),
							_updated: el.updated_ts ? (new Date(el.updated_ts)).toLocaleString("ru") : '',
							_amount: formatAmount(el.amount, 2),
							_agent: `${this.getAgentName(el.agent_in)}→${this.getAgentName(el.agent_out)}`,
							_status: el.status,
							_color: this.getStatusColor(el.status),
							_course: `${formatAmount(buy_rate, 2)} / ${sell_rate ? formatAmount(sell_rate, 2) : '-'} (${profit ? formatAmount(profit, 2) : '-'})`
						}
					});
				})
				.catch(err => console.warn(err))
				.finally(() => this.loading = false);
		} catch (e) {
			console.warn(e);
		}
	}

	getStatusColor(status: string) {
		const preset = getStatusSettings(status);
		return preset.color;
	}

	getAgentName(agent: string) {
		const preset = getAgentSettings(agent);
		return preset.name;
	}

	rowClickHandler(item: any) {
		// console.log('>> rowClickHandler', item);
		this.editedItem = item;
		this.showItemCard = true;
	}

	async updateItemHandler(payload: any) {
		// if (payload) console.log('>>> updateItemHandler payload:', payload);
	}
}
