


















































































































































import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import {formatAmount} from "@/helper/format";
import {ApiService} from "@/service/api";

@Component({
  components: {}
})
export default class DashboardBalanceCard extends Vue {
  @PropSync('show', {type: Boolean}) _show: boolean | undefined;
  @PropSync('item') _item: any;

  DEFAULT_COLOR = 'grey lighten-1';

  loadingRemote = false;
  loadingUpdateRemote = false;
  loadingKryptexStatus = false;
  remote: any = null;

  kryptexStatus = '';
  kryptexStatusName = '';
  kryptexStatusColor = 'gray';
  kryptexActionName = 'Изменить статус';
  kryptexActionColor = 'gray';

  @Watch('_item')
  onItemChanged(val: boolean) {
    // console.log('>>> onItemChanged', val);
    this.remote = null;
  }

  @Watch('_show')
  onShowChanged(val: boolean) {
    if (this._item.id === 'kryptex') {
      if (val) {
        this.getKryptexStatus();
      } else {
        this.kryptexStatus = '';
        this.kryptexStatusName = '';
        this.kryptexStatusColor = 'gray--text';
        this.kryptexActionName = 'Изменить статус';
        this.kryptexActionColor = 'gray';
      }
    }
  }

  closeSettingsHandler(isSave: boolean) {
    // this.$emit('update:item', isSave ? this._item : null);
    this._show = false;
  }

  fmt(item: any) {
    return item ? formatAmount(item, 2) : item;
  }

  fmtSkip(item: any) {
    return item ? formatAmount(item, 2) : '-';
  }

  getRemoteBalance() {
    try {
      this.loadingRemote = true;
      ApiService.Instance.get(`balance/${this._item.id}-remote`)
        .then((res: any) => {
          // console.log(`>>> GET balance/${this._item.id}-remote res:`, res);

          if (res.data) {
            this.remote = {total: res.data?.total, hold: res.data?.hold, available: res.data?.available};
            return;
          }

          this.$store.dispatch('MAIN/showSnackbarError', 'Не удалось получить баланс. Попробуйте позже').then();
        })
        .catch((err: any) => {
          console.warn(err);
          this.$store.dispatch('MAIN/showSnackbarError', 'Не удалось получить баланс. Попробуйте позже').then();
        })
        .finally(() => this.loadingRemote = false);
    } catch (e) {
      console.warn(e);
    }
  }

  updateBalanceFromRemote() {
    try {
      this.loadingUpdateRemote = true;
      ApiService.Instance.post(`balance/${this._item.id}-remote-update`)
        .then((res: any) => {
          // console.log(`>>> GET balance/${this._item.id}-remote-update res:`, res);

          if (res.data && res.data.message) {
            this.$store.dispatch('MAIN/showSnackbarInfo', res.data.message).then();
            return;
          }

          this.$store.dispatch('MAIN/showSnackbarError', 'Не удалось обновить баланс').then();
        })
        .catch((err: any) => {
          console.warn(err);
          this.$store.dispatch('MAIN/showSnackbarError', 'Не удалось обновить баланс').then();
        })
        .finally(() => this.loadingUpdateRemote = false);
    } catch (e) {
      console.warn(e);
    }
  }

  processNewKryptexStatus(newStatus: string) {
    if (newStatus === 'on') {
      this.kryptexStatus = newStatus;
      this.kryptexStatusName = 'Включено';
      this.kryptexStatusColor = 'green--text';
      this.kryptexActionName = 'Выключить получение';
      this.kryptexActionColor = 'red';
    } else if (newStatus === 'off') {
      this.kryptexStatus = newStatus;
      this.kryptexStatusName = 'Выключено';
      this.kryptexStatusColor = 'red--text';
      this.kryptexActionName = 'Включить получение';
      this.kryptexActionColor = 'green';
    } else {
      this.kryptexStatus = 'service';
      this.kryptexStatusName = 'Сервис';
      this.kryptexStatusColor = 'gray--text';
      this.kryptexActionName = 'Изменить статус';
      this.kryptexActionColor = 'gray';
    }
  }

  getKryptexStatus() {
    try {
      this.loadingKryptexStatus = true;
      ApiService.Instance.get('settings/kryptex')
        .then((res: any) => {
          if (res.data && res.data.serviceStatus) {
            this.processNewKryptexStatus(res.data.serviceStatus);
            return;
          }

          this.$store.dispatch('MAIN/showSnackbarError', 'Не удалось получить статус сервиса').then();
        })
        .catch((err: any) => {
          console.error(err);
          this.$store.dispatch('MAIN/showSnackbarError', 'Не удалось получить статус сервиса').then();
        })
        .finally(() => this.loadingKryptexStatus = false);
    } catch (e) {
      console.warn(e);
    } finally {
      this.loadingKryptexStatus = false;
    }
  }

  setKryptexStatus() {
    try {
      if (!this.kryptexStatus) {
        this.$store.dispatch('MAIN/showSnackbarInfo', 'Необходимо запросить статус перед изменением').then();
        return;
      }

      if (this.kryptexStatus !== 'on' && this.kryptexStatus !== 'off') {
        this.$store.dispatch('MAIN/showSnackbarInfo', 'Невозможно изменить статус в данный момент').then();
        return;
      }

      const newServiceStatus = this.kryptexStatus === 'on' ? 'off' : 'on';

      this.loadingKryptexStatus = true;
      ApiService.Instance.post('settings/kryptex', {serviceStatus: newServiceStatus})
        .then((res: any) => {
          if (res.data && res.data.status === 'ok') {
            this.processNewKryptexStatus(newServiceStatus);
            return;
          }

          this.$store.dispatch('MAIN/showSnackbarError', 'Не удалось изменить статус. Попробуйте позже').then();
        })
        .catch((err: any) => {
          console.error(err);
          this.$store.dispatch('MAIN/showSnackbarError', 'Не удалось изменить статус. Попробуйте позже').then();
        })
        .finally(() => this.loadingKryptexStatus = false);
    } catch (e) {
      console.warn(e);
    } finally {
      this.loadingKryptexStatus = false;
    }
  }
}
