
























	import {Component, Inject, Vue} from 'vue-property-decorator';
	import router from "@/router";

	@Component({
		components: {}
	})
	export default class AdmReports extends Vue {
		// mounted() {}

		openReport01() {
			router.push('/adm_report_01').catch((error) => {console.log(error)});
    }

		openReport02() {
			router.push('/adm_report_02').catch((error) => {console.log(error)});
		}
	}
