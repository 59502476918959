import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugin/vuetify";
import provide from "@/store/const";

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	vuetify,
	provide,
	render: h => h(App)
}).$mount("#app");
