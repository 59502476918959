interface IConfig {
	name?: string
	apiBaseURI: string
	wsBaseURI?: string
	clientDefaults?: {
		login?: string,
		password?: string
	}
}

const envConfig = {
	local: {
		name: 'local',
		apiBaseURI: 'http://localhost:3001/app/v1/',
		wsBaseURI: 'ws://localhost:3001/ws',
		// clientDefaults: {
		// 	login: 'super@mail.ru',
		// 	password: '12345'
		// }
	},
	prod: {
		name: 'prod',
		apiBaseURI: 'https://ktx-back.bitpulse.me/app/v1/',
		wsBaseURI: 'wss://ktx-back.bitpulse.me/ws',
	}
};

let config: IConfig;

switch (process.env.NODE_ENV) {
	case 'local':
		config = envConfig.local;
		break;
	case 'prod':
		config = envConfig.prod;
		break;
	default:
		config = envConfig.prod;
}

export default Object.freeze(config);
