// import {GetterTree, MutationTree, ActionTree} from "vuex";
import {MutationTree, ActionTree, GetterTree} from "vuex";

import AuthService, {IEmailActivator} from '@/service/auth';
import router from "@/router";

export interface IAuthStateStatus {
	loggedIn: boolean
}

export interface IAuthStateUser {
	email?: string
	id?: number
	active?: boolean
	base_role?: string
	permission?: string[]
	banks?: any[]
	default_route?: string
}

export interface IAuthState {
	status: IAuthStateStatus
	user: IAuthStateUser | null
}

class State implements IAuthState {
	status = {
		loggedIn: false
	};

	user: null;

	constructor() {
		// const lsUser = JSON.parse(localStorage.getItem('user')!);
		//
		// if (lsUser) {
		// 	this.status = {loggedIn: true};
		// 	this.user = lsUser;
		// } else {
		// 	this.status = {loggedIn: false};
		// 	this.user = null;
		// }
	}
}

const getters = <GetterTree<State, any>>{
	getHavePermission: (state) => (permission: string) : boolean => {
		try {
			return (state.user as any).permission.findIndex((el: any) => el === permission) !== -1;
		} catch {
			return false;
		}
	}
};

const actions = <ActionTree<State, any>>{
	login({dispatch, commit}, user: any) {
		return AuthService.login(user)
			.then(
				data => {
					if (data.user) {
						commit('LOGIN_SUCCESS', data.user);
					} else {
						if (data.action === 'register_otp' && data.token) {
							commit('LOGIN_LIMITED_SUCCESS');
						}
					}

					if (data.token) localStorage.setItem('token', data.token);
					return Promise.resolve(data);
				},
				error => {
					commit('LOGIN_FAILURE');
					return Promise.reject(error);
				}
			);
	},
	loginConfirm({commit}, code: string) {
		return AuthService.loginConfirm(code)
			.then(
				data => {
					if (data.user) commit('LOGIN_SUCCESS', data.user);
					return Promise.resolve(data);
				},
				error => {
					commit('LOGIN_FAILURE');
					return Promise.reject(error);
				}
			);
	},
	logout({commit}, error = false) {
		// console.log('>> Store Auth logout', error);
		if (error) {
			router.push('/login').then();
		} else {
			AuthService.logout().then();
		}
		commit('logout');
	},
	register({commit}, user: any) {
		return AuthService.register(user).then(
			response => {
				commit('REGISTER_SUCCESS');
				return Promise.resolve(response.data);
			},
			error => {
				commit('REGISTER_FAILURE');
				return Promise.reject(error);
			}
		);
	},
	refresh({commit}, user: any) {
		return AuthService.login(user).then(
			data => {
				commit('LOGIN_SUCCESS', data.user);
				return Promise.resolve(data);
			},
			error => {
				commit('LOGIN_FAILURE');
				return Promise.reject(error);
			}
		);
	},
	refreshUser({commit}, user: any) {
		commit('LOGIN_SUCCESS', user);
		return Promise.resolve(user);
	},
	refreshSession({commit}, data: any) {
		if (data.token) localStorage.setItem('token', data.token);
		commit('LOGIN_SUCCESS', data.user);
		return Promise.resolve(data.user);
	},
	activateEmail({commit}, payload: IEmailActivator) {
		return AuthService.activateEmail(payload);
	},
	resetPassword({commit}, payload: IEmailActivator) {
		return AuthService.resetPassword(payload);
	},
	updateUserBanks({commit}, payload: IEmailActivator) {
		commit('BANKS_UPDATE', payload);
		return Promise.resolve(payload);
	},
};

const mutations = <MutationTree<State>>{
	LOGIN_SUCCESS(state: IAuthState, user: IAuthStateUser) {
		state.status.loggedIn = true;
		state.user = user;
	},
	LOGIN_LIMITED_SUCCESS(state: IAuthState) {
		state.status.loggedIn = true;
	},
	LOGIN_FAILURE(state: IAuthState) {
		state.status.loggedIn = false;
		state.user = null;
	},
	logout(state: IAuthState) {
		state.status.loggedIn = false;
		state.user = null;
	},
	REGISTER_SUCCESS(state: IAuthState) {
		state.status.loggedIn = false;
	},
	REGISTER_FAILURE(state: IAuthState) {
		state.status.loggedIn = false;
	},
	BANKS_UPDATE(state: IAuthState, banks: any) {
		state.user = {
			...state.user,
			banks: banks
		};
	}
};

const AuthModule = {
	namespaced: true,
	state: new State(),
	getters: getters,
	mutations: mutations,
	actions: actions
};

export default AuthModule;
