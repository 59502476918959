




import {Component, Vue} from 'vue-property-decorator';
// import AppSidebar from "@/components/layouts/AppSidebar.vue";
// import AppBar from "@/components/layouts/AppBar.vue";

@Component({
	components: {
		// AppSidebar,
		// AppBar
	},
})
export default class App extends Vue {

}
