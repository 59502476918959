export const DEPOSIT_STATUS: any = {
	CREATED: {name: 'Создан', color: ''},
	CONFIRMED: {name: 'Оплачен', color: 'green'},
	REJECTED: {name: 'Отменен', color: 'grey lighten-1'}
};

export const getStatusSettings = (status: string) => {
	const preset = DEPOSIT_STATUS[status];
	if (preset) return preset;
	return {name: 'N/A', color: ''};
};
