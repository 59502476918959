















































































	import {Component, Inject, Vue} from 'vue-property-decorator';
	import {ApiService} from '@/service/api';
	import store from "@/store";

	@Component({
		components: {}
	})
	export default class AppSettings extends Vue {

		settings: any[] = [];

		confirmDialog = false;

		extraActions() {
			return [
				{label: 'Reload settings', action: this.reloadSettings},
				{label: 'ALK swap accounts', action: this.alkSwapAccounts},
			];
		}

		mounted() {
			this.fetchSettings();
		}

		parseSettings(objSettings: any) {
			if (!objSettings) return;

			const settings = [];
			for (const el in objSettings) {
				const item: any = {};
				item.id = el;
				item.value = objSettings[el];
				settings.push(item);
			}

			// this.settings = res?.data?.settings;
			this.settings = settings;
		}

		fetchSettings() {
			try {
				ApiService.Instance.get("settings")
					.then(res => {
						this.parseSettings(res?.data?.settings);
					})
					.catch(err => console.warn(err));
			} catch (e) {
				console.warn(e);
			}
		}

		reloadSettings() {
			try {
				ApiService.Instance.post("settings/reload")
					.then(res => {
						if (res?.data?.message) this.showSnackbar(res?.data?.message);
						this.parseSettings(res?.data?.settings);
					})
					.catch(err => {
						console.warn(err);
						if (err?.data?.message) this.showSnackbar(err?.data?.message, 'error');
					});
			} catch (e) {
				console.warn(e);
			}
		}

    alkSwapAccounts() {
      try {
        ApiService.Instance.post("settings/alk-swap-accounts")
            .then(res => {
              if (res?.data?.message) this.showSnackbar(res?.data?.message);
              this.fetchSettings();
            })
            .catch(err => {
              console.warn(err);
              if (err?.data?.message) this.showSnackbar(err?.data?.message, 'error');
            });
      } catch (e) {
        console.warn(e);
      }
    }

		updateSettings() {
			try {
				const objParam = this.settings.reduce((obj, item) => Object.assign(obj, {[item.id]: item.value}), {});

				// console.log('>>>', objParam);

				ApiService.Instance.post("settings/update", objParam)
					.then(res => {
						if (res?.data?.message) this.showSnackbar(res?.data?.message);
					})
					.catch(err => {
						console.warn(err);
						if (err?.data?.message) this.showSnackbar(err?.data?.message, 'error');
					});
			} catch (e) {
				console.warn(e);
			} finally {
				this.confirmDialog = false;
			}
		}

		showSnackbar(message: string, type = '') {
			store.dispatch(type === 'error' ? 'MAIN/showSnackbarError' : 'MAIN/showSnackbarInfo', message).then();
		}

		// @Watch('cardOpened')
		// onCardOpenedChanged(val: boolean, oldVal: boolean) {
		// 	console.log('>> cardOpened watch', val, oldVal);
		// }
	}
