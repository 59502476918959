







































































































import {Component, Inject, Vue, Watch} from 'vue-property-decorator';
import {ApiService} from '@/service/api';
import {DataTableHeader} from "vuetify";
import {formatAmount, formatDateYMD} from "@/helper/format";

@Component({
	components: {}
})
export default class AdmReport01 extends Vue {

	headers: DataTableHeader[] = [
		{text: 'Дата', value: 'date'},
		{text: 'Кол-во', value: 'cnt', align: 'end'},
		{text: 'Сумма', value: 'sum', align: 'end'},
		{text: 'Оплачено', value: 'sumPaid', align: 'end'},
	];

	totalItems = 1000;
	loading = false;
	options: any = {
		itemsPerPage: 50
	};
	footerProps: any = {
		"items-per-page-options": [15, 50, 100]
	};


	tData: any[] = [];
	tCount: any = '';
	tSumPaid: any = '';
	tSum: any = '';

	periodStart = '';
	periodEnd = '';

	showStart = false;
	showEnd = false;

	$refs!: {
		wrapStart: HTMLFormElement,
		wrapEnd: HTMLFormElement
	};

	@Watch('periodStart')
	onPeriodStartChanged(val: any, oldVal: any) {
		if (oldVal !== val)
			this.showStart = false;
	}

	@Watch('periodEnd')
	onPeriodEndChanged(val: any, oldVal: any) {
		if (oldVal !== val)
			this.showEnd = false;
	}

	mounted() {
		const date = new Date();
		const startMonth = new Date(date.getFullYear(), date.getMonth(), 1);
		this.periodStart = formatDateYMD(startMonth);
		// new Date(date.getFullYear(), date.getMonth() + 1, 0);
		// this.periodEnd = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
		this.periodEnd = formatDateYMD(new Date());
		this.fetchSettings();
	}

	fetchSettings() {
		try {
			let params = {
				params: {
					start: this.periodStart,
					end: this.periodEnd
				}
			};

			this.loading = true;
			ApiService.Instance.get(`/qtr/report`, params)
				.then(res => {
					// console.log('>>>', res.data);
					const newData: any[] = [];
					let newCount = 0;
					let newSumPaid = 0;
					let newSum = 0;
					res.data.forEach((item: any) => {
						const nCnt = Number(item.cnt_amount);
						const nSumPaid = Number(item.sum_paid_amount);
						const nSum = Number(item.sum_amount);
						newData.push({
							date: (new Date(item.date)).toLocaleString("ru").substring(0, 10),
							cnt: formatAmount(nCnt, 0),
							sumPaid: formatAmount(nSumPaid, 2),
							sum: formatAmount(nSum, 2)
						});
						newCount += nCnt;
						newSumPaid += Number(nSumPaid);
						newSum += Number(nSum);
					});

					this.tData = newData;
					this.tCount = formatAmount(newCount, 0);
					this.tSum = formatAmount(newSum, 2);
					this.tSumPaid = formatAmount(newSumPaid, 2);
					this.totalItems = res.data?.length ? res.data.length : 0;
				})
				.catch(err => console.warn(err))
				.finally(() => this.loading = false);
		} catch (e) {
			console.warn(e);
		}
	}

	applyReportParam() {
		this.fetchSettings();
	}
}
