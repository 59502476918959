














































import {Component, Vue} from 'vue-property-decorator';
import TransfersList from "@/components/operator/TransfersList.vue";
import {getStatusSettings} from "@/model/transfer";

@Component({
	components: {
		TransfersList
	}
})
export default class Transfers extends Vue {
	anySearch = '';
	statusSelect = [];
	statuses = ['CREATED', 'PROCESS', 'MANUAL', 'PAID', 'CANCELED'];
	filters = {};

	getStatusColor(status: string) {
		const preset = getStatusSettings(status);
		return preset.color;
	}

	applyFilters() {
		this.filters = {
			statuses: this.statusSelect,
			anySearch: this.anySearch
		}
	}

	clearFilters() {
		this.anySearch = '';
		this.statusSelect = [];
		this.filters = {}
	}
}
