




























import {Component, Inject, Vue} from 'vue-property-decorator';
import {ApiService} from '@/service/api';
import {DataTableHeader} from "vuetify";
import {formatAmount, formatDateYMD} from "@/helper/format";
import {getAgentSettings} from "@/model/agent";

@Component({
	components: {}
})
export default class AdmReports extends Vue {
	headers: DataTableHeader[] = [
		{text: 'id', value: 'id'},
		{text: 'agent', value: '_agent'},
		{text: 'bank', value: 'bank'},
		{text: 'currency', value: 'currency'},
		{text: 'min_amount', value: 'min_amount', align: 'end'},
		{text: 'max_amount', value: 'max_amount', align: 'end'},
		{text: 'fee_percent', value: 'fee_percent', align: 'end'},
		{text: 'fee_amount', value: 'fee_amount', align: 'end'},
		{text: 'priority', value: 'priority', align: 'end'},
		{text: 'active', value: 'active'},
	];

	tData: any[] = [];
	loading = false;

	mounted() {
		this.fetchSettings();
	}

	fetchSettings() {
		try {
			this.loading = true;
			ApiService.Instance.get(`settings/order-allocation-settings`)
				.then(res => {
					this.tData = res.data.map((el: any) => {
						return {
							...el,
							_agent: this.getAgentName(el.agent_id),
						}
					});
				})
				.catch(err => console.warn(err))
				.finally(() => this.loading = false);
		} catch (e) {
			console.warn(e);
		}
	}

	applyRefresh() {
		this.fetchSettings();
	}

	getAgentName(agent: string) {
		const preset = getAgentSettings(agent);
		return preset.name;
	}
}
