







































































































import {Component, Inject, Vue, Watch} from 'vue-property-decorator';
	import {ApiService} from '@/service/api';
	import {DataTableHeader} from "vuetify";
	import {formatAmount, formatDateYMD} from "@/helper/format";

	@Component({
		components: {}
	})
	export default class AdmReport01 extends Vue {

		headers: DataTableHeader[] = [
			{text: 'Дата', value: 'date'},
			{text: 'Кол-во', value: 'cnt', align: 'end'},
			{text: 'Среднее', value: 'avg', align: 'end'},
			{text: 'Сумма', value: 'sum', align: 'end'},
			{text: 'Профит', value: 'profit', align: 'end'},
		];

		totalItems = 1000;
		loading = false;

		options: any = {
			itemsPerPage: 50
		};

		footerProps: any = {
			"items-per-page-options": [15, 50, 100]
		};

		tData: any[] = [];
		tCount: any = '';
		tAvg: any = '';
		tSum: any = '';
		tProfit: any = '';

		periodStart = '';
		periodEnd = '';

		showStart = false;
		showEnd = false;

		$refs!: {
			wrapStart: HTMLFormElement,
			wrapEnd: HTMLFormElement
		};

		@Watch('periodStart')
		onPeriodStartChanged(val: any, oldVal: any) {
			if (oldVal !== val)
				this.showStart = false;
		}

		@Watch('periodEnd')
		onPeriodEndChanged(val: any, oldVal: any) {
			if (oldVal !== val)
				this.showEnd = false;
		}

		mounted() {
			const date = new Date();
			const startMonth = new Date(date.getFullYear(), date.getMonth(), 1);
			this.periodStart = formatDateYMD(startMonth);
			// new Date(date.getFullYear(), date.getMonth() + 1, 0);
			// this.periodEnd = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
			this.periodEnd = formatDateYMD(new Date());
			this.fetchSettings();
		}

		fetchSettings() {
			try {
				let params = {
					params: {
						start: this.periodStart,
						end: this.periodEnd
					}
				};

				this.loading = true;
				ApiService.Instance.get(`reports/1`, params)
					.then(res => {
						// console.log('>>>', res.data);
						const newData: any[] = [];
						let newCount = 0;
						// let newAvg = 0;
						let newSum = 0;
						let newProfit = 0;
						res.data.forEach((item: any) => {
							const nCnt = Number(item.cnt_amount);
							const nAvg = Number(item.avg_amount);
							const nSum = Number(item.sum_amount);
							const nProfit = Number(item.profit);
							newData.push({
								date: (new Date(item.date)).toLocaleString("ru").substring(0, 10),
								cnt: formatAmount(nCnt, 0),
								avg: formatAmount(nAvg, 2),
								sum: formatAmount(nSum, 2),
								profit: formatAmount(nProfit, 2)
							});
							newCount += nCnt;
							// newAvg += Number(nAvg);
							newSum += Number(nSum);
							newProfit += Number(nProfit);
						});

						this.tData = newData;
						this.tCount = formatAmount(newCount, 0);
						this.tAvg = formatAmount(newSum / newCount, 2);
						this.tSum = formatAmount(newSum, 2);
						this.tProfit = formatAmount(newProfit, 2);
						this.totalItems = res.data?.length ? res.data.length : 0;
					})
					.catch(err => console.warn(err))
					.finally(() => this.loading = false);
			} catch (e) {
				console.warn(e);
			}
		}

		applyReportParam() {
			this.fetchSettings();
		}

		// @Watch('cardOpened')
		// onCardOpenedChanged(val: boolean, oldVal: boolean) {
		// 	console.log('>> cardOpened watch', val, oldVal);
		// }
	}
