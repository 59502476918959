export const TRANSFER_STATUS: any = {
	CREATED: {name: 'Создан', color: ''},
	PAID: {name: 'Оплачен', color: 'green'},
	CANCELED: {name: 'Отменен', color: 'grey lighten-1'},
	PROCESS: {name: 'Процесс', color: 'blue'},
	MANUAL: {name: 'Ручной', color: 'red'},
};

export const getStatusSettings = (status: string) => {
	const preset = TRANSFER_STATUS[status];
	if (preset) return preset;
	return {name: 'N/A', color: ''};
};
