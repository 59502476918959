




















































	import {Component, Vue} from 'vue-property-decorator';
	import {ApiService} from "@/service/api";
	import {formatAmount} from '@/helper/format';
	import GarantexRefillCard from '@/components/operator/GarantexRefillCard.vue';
	import DashboardBalanceCard from '@/components/operator/DashboardBalanceCard.vue';

	@Component({
		components: {
			GarantexRefillCard,
			DashboardBalanceCard
		}
	})
	export default class DashboardBalances extends Vue {

		items: any[] | null = null;
		loading = false;
		showGarantexRefillCard = false;

		openedBalance: any = null;
		showBalanceCard = false;

		mounted() {
			this.getDataFromAPI();
		}

		getDataFromAPI() {
			try {
				this.loading = true;
				ApiService.Instance.get(`balance/local`)
					.then(res => {
						// console.log('>>> items:', res);
						this.items = [...res.data.filter((el: any) => el.enabled)];
					})
					.catch(err => console.warn(err))
					.finally(() => this.loading = false);
			} catch (e) {
				console.warn(e);
			}
		}

		itemClickHandler(item: any) {
			this.openedBalance = item;
			this.showBalanceCard = true;
		}

		fmtBalance(item: any, type: string) {
			return item?.balance ? formatAmount(item.balance[type], 2) : '-';
		}

		refillClickHandler() {
			this.showGarantexRefillCard = true;
		}

		isMobile () {
			return this.$vuetify.breakpoint.name === 'xs';
				// case 'xs': return 220
				// case 'sm': return 400
				// case 'md': return 500
				// case 'lg': return 600
				// case 'xl': return 800
		}

		cardMaxWidth () {
			return this.isMobile() ? '100%' : 240;
    }

		cardMinWidth () {
			return this.isMobile() ? '100%' : 180;
		}

	}
