






































































	import {Component, Inject, Vue} from "vue-property-decorator";
	import AuthLayout from "@/view/auth/AuthLayout.vue";

	@Component({
		components: {
			AuthLayout
		}
	})
	export default class PasswordReset extends Vue {
		@Inject() readonly MIN_PASSWORD_LENGTH!: number;

		email = '';
		password = '';
		showPassword = false;
		valid = false;
		step = 'init';
		snackbarShow = false;
		snackbarText = '';
		loading = false;

		rules = {
			required: (value: string) => !!value || 'Обязательное',
			min: (v: string) => v.length >= this.MIN_PASSWORD_LENGTH || `Минимум ${this.MIN_PASSWORD_LENGTH} символов`,
		};

		$refs!: {
			password: HTMLFormElement
		};

		submit() {
			this.$refs.password.validate(true);
			Vue.nextTick(async () => {
				if (this.valid) this.processSubmit();
			});
		}

		processSubmit() {
			this.loading = true;
			this.$store.dispatch("auth/resetPassword", {
				token: this.$route.params.token,
				password: this.password
			}).then(
				(res) => {
					// console.log('>> ActivateEmail res:', res);
					if (res.status !== 200)
						return;
					this.step = 'final';
				},
				(error) => {
					// console.log('>> ActivateEmail error:', error.response);
					this.snackbarText = error.response?.data?.message ? error.response?.data?.message : 'Ошибка операции';
					this.snackbarShow = true;
				}
			).finally(() => this.loading = false);
    }

		okClickHandler() {
			this.$router.push('/login').then();
		}

		// mounted() {
		// 	console.log('>> ResetPassword mounted');
		// }
	}
