
































































import {Component, PropSync, Vue, Inject, Watch} from 'vue-property-decorator';
import {IUser} from '@/model';
import {IComboboxValue} from '@/store/const';
import {ApiService} from "@/service/api";
import store from "@/store";

const def_rules: any = {
	email: [],
	base_role: []
};
const def_newUser: IUser = {base_role: 'oper', email: ''};
const def_baseRole = {value: 'oper', text: 'Оператор'};

@Component({
	components: {}
})
export default class UserInvite extends Vue {
	@PropSync('dialog', {type: Boolean}) _dialog: boolean | undefined;
	@Inject() readonly USER_ROLES!: IComboboxValue[];

	valid = true;
	baseRole = def_baseRole;
	newUser: IUser = def_newUser;
	rules = def_rules;
	loading = false;

	init() {
		this.baseRole = {...def_baseRole};
		this.newUser = {...def_newUser};
		this.rules = {...def_rules};
	}

	baseRoleChangeHandler(val: any) {
		this.newUser.base_role = val.value;
	}

	validateFields() {
		this.rules.email = [];
		if (!this.newUser.email) this.rules.email.push('Обязательное поле');
		if (!/.+@.+\..+/.test(this.newUser.email as string)) this.rules.email.push('Email не валидный');
		this.rules.base_role = [];
		if (!this.newUser.base_role) this.rules.base_role.push('Обязательное поле');
		(this.$refs.form as Vue & { validate: () => boolean }).validate();
	}

	cancelHandler() {
		this._dialog = false;
	}

	saveHandler() {
		this.validateFields();
		Vue.nextTick(async () => {
			if (this.valid) {
				const res = await this.processInviteUser();
				if (res) {
					this.$emit('process', this.newUser);
					this._dialog = false;
				}
			}
		});
	}

	async processInviteUser() {
		let errMessage = 'Ошибка отправки запроса';
		try {
			this.loading = true;
			const res = await ApiService.Instance.post(`users/invite`, this.newUser);
			if (res.status === 200)
				return true;

			errMessage = res.data; // ToDo
		} catch (e) {
			console.warn('>> UserInvite processInviteUser catch:', e.data);
			errMessage = e.data;
		} finally {
			this.loading = false;
		}

		store.dispatch('MAIN/showSnackbarError', errMessage).then();
		return false;
	}

	@Watch('_dialog')
	onCardOpenedChanged(val: boolean) {
		if (val) this.init();
	}
}
