



































	import {Component, Vue} from 'vue-property-decorator';

	@Component({
		components: {}
	})
	export default class UserEvents extends Vue {
		enabled2fa = false;

		getQRCodeUrl() {
			const code = 'test code';
			return `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${code}&choe=UTF-8`;
    }
  }
