















































































































import {Component, Inject, Vue} from "vue-property-decorator";
import AuthLayout from "@/view/auth/AuthLayout.vue";
import {namespace} from "vuex-class";
import {ApiService} from "@/service/api";
import store from "@/store";
import router from "@/router";

const authStore = namespace("auth");

@Component({
	components: {
		AuthLayout
	}
})
export default class Setup2fa extends Vue {
	@Inject() readonly MIN_PASSWORD_LENGTH!: number;
	@Inject() readonly DEFAULT_ROUTES!: Map<string, string>;

	@authStore.Action("logout")
	private logout!: () => Promise<void>;

	GA_APP_STORE_URL = 'https://apps.apple.com/us/app/google-authenticator/id388497605';
	GA_GOOGLE_PLAY_URL = 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en';

	step = 0;
	init_step = 0;
	final_step = 2;
	snackbarShow = false;
	snackbarText = '';
	loading = false;

	codeBase64 = '';
	code2fa = '';

	getQRCodeUrl() {
		return `https://chart.googleapis.com/chart?chs=300x300&cht=qr&chl=${this.codeBase64}&choe=UTF-8`;
	}

	async nextStepHandler() {

		// инициируем событие включения второго фактора
		if (this.step === 0) {
			const res = await this.enable2fa();
			if (!res) return
		}

		if (this.step === 1) {
			const res = await this.enable2faConfirm();
			if (!res) return
		}

		this.step = this.step + 1;
	}

	prevStepHandler() {
		this.step = this.step - 1;
	}

	okHandler() {
		router.push(this.DEFAULT_ROUTES.get(this.$store.state.auth.user.base_role) as string).then();
	}

	// mounted() {
	// 	console.log('>> Activate2fa mounted');
	// }

	async enable2fa() {
		let errMessage = 'Ошибка отправки запроса';
		try {
			const res = await ApiService.Instance.post(`users/enable2fa`);
			if (res.status === 200) {
				// this.codeBase64 = res.data.code;
				this.codeBase64 = res.data.otpauth_url;
				return true;
			}

			errMessage = res.data; // ToDo
		} catch (e) {
			console.warn('>> enable2fa catch:', e.data);
			errMessage = e.data?.msg ? e.data.msg : e.data;
		}

		store.dispatch('MAIN/showSnackbarError', errMessage).then();
		return false;
	}

	async enable2faConfirm() {
		let errMessage = 'Ошибка отправки запроса';
		try {
			const res = await ApiService.Instance.post(
				`users/enable2fa-confirm`,
				{code: this.code2fa},
				{
					withCredentials: true,
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					}
				});
			if (res.status === 200) {
				store.dispatch('auth/refreshSession', res.data).then();
				return true;
			}

			errMessage = res.data; // ToDo
		} catch (e) {
			console.warn('>> enable2fa catch:', e.data);
			errMessage = e.data?.msg ? e.data.msg : e.data;
		}

		store.dispatch('MAIN/showSnackbarError', errMessage).then();
		return false;
	}

}
