





































import {Component, PropSync, Vue} from 'vue-property-decorator';

import {ApiService} from "@/service/api";
import {DataTableHeader} from "vuetify";

@Component({
	components: {}
})
export default class TransferStatusesListModal extends Vue {
	@PropSync('show', {type: Boolean}) _show: boolean | undefined;
	@PropSync('transfer_id', {type: Number}) _transfer_id: number | undefined;

	headers: DataTableHeader[] = [
		{text: 'Дата/время', value: '_ts'},
		{text: 'Статус старый', value: 'old_status', sortable: false},
		{text: 'Статус новый', value: 'new_status', sortable: false},
		{text: 'Инициатор', value: 'user_name', sortable: false},
	];

	loading = false;
	items: any[] = [];

	mounted() {
		this.getData();
	}

	getData() {
		try {
			this.loading = true;
			ApiService.Instance.get(`transfers/status-history`, {params: {id: this._transfer_id}})
				.then(res => {
					this.items = res.data.data.map((el: any) => {
						return {
							...el,
							_ts: (new Date(el.ts)).toLocaleString("ru"),
						}
					});
				})
				.catch(err => console.warn(err))
				.finally(() => this.loading = false);
		} catch (e) {
			console.warn(e);
		}
	}
}
