






























import {Component, Vue} from 'vue-property-decorator';
import {ApiService} from '@/service/api';
import {IUser} from '@/model';

@Component({
	components: {}
})
export default class UserList extends Vue {
	users: IUser[] = [];

	mounted() {
		// console.log('UserList mounted');
		try {

			ApiService.Instance.get("users")
				.then(res => {
					this.users = [...res.data];
				})
				.catch(err => console.warn(err));
		} catch (e) {
			console.warn(e);
		}
	}
}
