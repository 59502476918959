








import {Component, Vue} from 'vue-property-decorator';
import {ApiService} from "@/service/api";
import {formatAmount} from '@/helper/format';

@Component({
	components: {}
})
export default class DashboardInfo extends Vue {

	loading = false;
	info = '';

	mounted() {
		this.getDataFromAPI();
	}

	getDataFromAPI() {
		try {
			this.loading = true;
			ApiService.Instance.get(`reports/dashboard-info`)
				.then(res => {
					if (!res.data.info.length)
						return;

					let infoRes: any[] = [];
					res.data.info.forEach((el: any) => {
						infoRes.push(`${el.agent} ${el.status}: ${formatAmount(Number(el.amount), 0)}`)
					});
					this.info = infoRes.join('; ');
				})
				.catch(err => console.warn(err))
				.finally(() => this.loading = false);
		} catch (e) {
			console.warn(e);
		}
	}


}
