import {RouteConfig} from 'vue-router';

import HomeT from '../view/tmp/Home.vue';
import HomeStd from '../view/tmp/HomeStd.vue';
import Login from '../view/auth/Login.vue';
import ActivateEmail from '../view/auth/ActivateEmail.vue';
import PasswordReset from "@/view/auth/PasswordReset.vue";
import Private from '@/view/Private.vue';
import UsersList from '@/view/tmp/UsersList.vue';
import AdmUsersList from '@/view/admin/UsersList.vue';
import AdmSettings from '@/view/admin/AdmSettings.vue';
import AppReports from '@/view/admin/AdmReports.vue';
import AppReport01 from '@/view/admin/AdmReport01.vue';
import AppReport02 from '@/view/admin/AdmReport02.vue';
import {INavListItem} from '@/model';
import Test1 from '@/view/tmp/Test1.vue';
import Settings from '@/view/Settings.vue';

import store from '@/store';
import Setup2fa from '@/view/auth/Setup2fa.vue';
import Dashboard from '@/view/operator/Dashboard.vue';
import Transfers from '@/view/operator/Transfers.vue';
import Deposits from '@/view/operator/Deposits.vue';

import PayOperActiveTransfers from '@/view/pay-oper/ActiveTransfers.vue';
import PayOperTransfersHistory from '@/view/pay-oper/TransfersHistory.vue';
import PayOperReport01 from '@/view/pay-oper/PayoperReport01.vue';


const privateRoutes: Array<RouteConfig> = [
	// OPER
	{
		path: "/dashboard",
		name: "Dashboard",
		component: Dashboard,
		meta: {
			perm: 'opr_dashboard',
			icon: 'mdi-home-account'
		},
	},
	{
		path: "/transfers",
		name: "Transfers",
		component: Transfers,
		meta: {
			perm: 'opr_transfer',
			title: 'Трансферы',
			icon: 'mdi-credit-card-fast-outline'
		},
	},
	{
		path: "/deposits",
		name: "Deposits",
		component: Deposits,
		meta: {
			perm: 'opr_dashboard',
			title: 'Депозиты',
			icon: 'mdi-credit-card-plus-outline'
		},
	},
	// ADMIN
	{
		path: "/adm_home",
		name: "Admin Home",
		component: AdmUsersList,
		meta: {
			perm: 'adm_user_management',
			title: 'Пользователи',
			icon: 'mdi-account-group-outline'
		},
	},
	{
		path: "/adm_settings",
		name: "Adm Settings",
		component: AdmSettings,
		meta: {
			perm: 'adm_settings',
			title: 'Настройки сервиса',
			icon: 'mdi-database-cog-outline'
		},
	},
	{
		path: "/adm_reports",
		name: "Reports",
		component: AppReports,
		meta: {
			perm: 'adm_reports',
			title: 'Отчеты (админ)',
			icon: 'mdi-finance'
		},
	},
	{
		path: "/adm_report_01",
		name: "Report 01",
		component: AppReport01,
		meta: {
			perm: 'adm_reports',
			title: 'Итоги за период (кол-во, среднее)',
			noMenu: true
		},
	},
	{
		path: "/adm_report_02",
		name: "Report 02",
		component: AppReport02,
		meta: {
			perm: 'adm_reports',
			title: 'Итоги по агентам за период',
			noMenu: true
		},
	},
	// PAY_OPER
	{
		path: "/active-transfers",
		name: "ActiveTransfers",
		component: PayOperActiveTransfers,
		meta: {
			perm: 'pay_oper',
			title: 'Трансферы в работе',
			icon: 'mdi-credit-card-clock-outline'
		},
	},
	{
		path: "/transfers-history",
		name: "TransfersHistory",
		component: PayOperTransfersHistory,
		meta: {
			perm: 'pay_oper',
			title: 'История трансферов',
			icon: 'mdi-credit-card-search-outline'
		},
	},
	{
		path: "/payoper-report",
		name: "PayoperReport",
		component: PayOperReport01,
		meta: {
			perm: 'pay_oper',
			title: 'Отчет',
			icon: 'mdi-finance'
		},
	},
	// COMMON
	{
		path: "/settings/",
		name: "setting",
		component: Settings,
		meta: {
			title: 'Настройки',
		},
	},
	{
		path: "/settings/:tab",
		name: "setting",
		component: Settings,
		meta: {
			title: 'Настройки',
		},
	},
	// TEST
	{
		path: "/t1",
		name: "HomeT",
		component: HomeT,
		meta: {
			title: 'Домашняя',
			perm: 'test',
			icon: 'mdi-home-account'
		},
	},
	{
		path: "/t2",
		name: "Users List",
		component: UsersList,
		meta: {
			title: 'Список пользователей',
			perm: 'test',
			icon: 'mdi-account-group-outline'
		},
	},
	{
		path: "/t3",
		name: "HomeStd",
		component: HomeStd,
		meta: {
			title: 'Домашняя 2',
			perm: 'test',
			icon: 'mdi-home-alert'
		},
	},
	// {
	// 	path: "/t4",
	// 	name: "About",
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import(/* webpackChunkName: "about" */ "../view/tmp/About.vue"),
	// 	meta: {
	// 		title: 'О нас',
	// 		perm: 'test',
	// 		icon: 'mdi-information-outline'
	// 	}
	// },
	{
		path: "/t5",
		name: "Test1",
		component: Test1,
		meta: {
			perm: 'test',
			title: 'Тест',
			icon: 'mdi-home-alert'
		},
	},
];

export const allRoutes: Array<RouteConfig> = [
	{
		path: "/",
		redirect: "/login"
	},
	{
		path: "/login",
		name: "login",
		component: Login
	},
	{
		path: "/activate/:token",
		name: "activate",
		component: ActivateEmail
	},
	{
		path: "/reset-password/:token",
		name: "reset-password",
		component: PasswordReset
	},
	{
		path: "/setup2fa",
		name: "setup2fa",
		meta: {requireAuth: true},
		component: Setup2fa
	},
	{
		path: "/private",
		component: Private,
		meta: {requireAuth: true},
		children: privateRoutes,
	}
];

export const getAvailableRoutes = (): Array<RouteConfig> => {
	return allRoutes;
};

export const getSidebarMenu = (): INavListItem[] => {
	const res: INavListItem[] = [];

	const userPermission: string[] = store.state?.auth?.user?.permission;
	if (!userPermission && Array.isArray(userPermission)) {
		console.warn("Can't access. Undefined state");
		return res;
	}

	let prevRole = '';
	privateRoutes.forEach(el => {
		if (userPermission.indexOf(el.meta?.perm) !== -1 && !el.meta?.noMenu) {
			res.push({
				title: el.meta?.title ? el.meta?.title : el.name,
				icon: el.meta?.icon ? el.meta?.icon : "mdi-home-account",
				link: el.path,
				divider: prevRole !== el.meta?.perm,
			});
			prevRole = el.meta?.role;
		}
	});
	return res;
};




