import Vue from "vue";
import router from "@/router";

// import config from '@/config';
//import axios from 'axios';
import {ApiService} from '@/service/api';
import config from "@/config";

export interface IUserAuth {
	login: string
	password: string
	name?: string
}

export interface IEmailActivator {
	token: string
	password: string
}

class AuthService extends Vue {
	login(user: IUserAuth) {
		return ApiService.Instance
			.post('login', {
				login: user.login,
				password: user.password
			}, {
				withCredentials: true,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json; charset=utf-8'
				},
			})
			.then(response => {
				// if (response.data.token) {
				// 	localStorage.setItem('user', JSON.stringify(response.data.user));
				// }

				// if (response.data.banks) {
				// 	localStorage.setItem('banks', JSON.stringify(response.data.banks));
				// }

				return Promise.resolve(response.data);
			})
			.catch(error => {
				return Promise.reject(error);
			});

		// return ApiService.Instance.request(
		// 	{
		// 		url: config.apiBaseURI + 'login',
		// 		method: 'post',
		// 		withCredentials: true,
		// 		credentials: 'include',
		// 		xsrfCookieName: "XSRF-TOKEN",
		// 		xsrfHeaderName: "X-XSRF-TOKEN",
		// 		headers: {
		// 			'Accept': 'application/json',
		// 			'Content-Type': 'application/json',
		// 		},
		// 		data: {
		// 			login: user.login,
		// 			password: user.password
		// 		}
		// 	})
		// 	.then(response => {
		// 		if (response.data.accessToken) {
		// 			localStorage.setItem('user', JSON.stringify(response.data));
		// 		}
		//
		// 		return Promise.resolve(response.data);
		// 	})
		// 	.catch(error => {
		// 		return Promise.reject(error);
		// 	});
	}

	loginConfirm(code: string) {
		return ApiService.Instance
			.post('login-confirm',
				{code: code},
				{
					withCredentials: true,
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json',
					},
				})
			.then(response => {
				if (response.data.token) {
					localStorage.setItem('user', JSON.stringify(response.data.user));
				}

				// if (response.data.banks) {
				// 	localStorage.setItem('banks', JSON.stringify(response.data.banks));
				// }

				return Promise.resolve(response.data);
			})
			.catch(error => {
				return Promise.reject(error);
			});
	}

	async logout() {
		await ApiService.Instance.post("logout", {}, {
			withCredentials: true,
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json',
			},
		})
			.then(res => {
				console.log('>> logout RES:', res);
			})
			.catch(err => console.warn('>> logout ERR:', err))
			.finally(() => {
				localStorage.removeItem('token');
			});

		if (router.currentRoute?.name !== 'login') {
			router.push('/login').then();
		}
	}

	register(user: IUserAuth) {
		return ApiService.Instance
			.post('signup', {
				username: user.name,
				email: user.login,
				password: user.password
			});
	}

	async activateEmail(payload: IEmailActivator) {
		return ApiService.Instance
			.post('activate', payload, {
				withCredentials: true,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			});
	}

	async resetPassword(payload: IEmailActivator) {
		return ApiService.Instance
			.post('reset-password', payload, {
				withCredentials: true,
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json',
				},
			});
	}
}

export default new AuthService();
