




























































































































































	import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
	import {Getter} from 'vuex-class';
	import {ApiService} from "@/service/api";
	import store from "@/store";
	import TransferStatusesListModal from "@/components/operator/TransferStatusesListModal.vue";

	@Component({
		components: {
			TransferStatusesListModal
		}
	})
	export default class TransferCard extends Vue {
		@PropSync('show', {type: Boolean}) _show: boolean | undefined;
		@PropSync('item') _item: any;

		@Getter('getHavePermission', { namespace: 'auth' })
		getHavePermission!: (permission: string) => boolean;

		DEFAULT_COLOR = 'grey lighten-1';
		editable = false;
		enableExtraMenu = false;
		loading = false;
		showStatusesCard = false;

		extraActions() {
			return [
				{label: 'KTX Sent', action: this.processKtxSent},
				{label: 'KTX Cancel', action: this.processKtxCancel},
			];
		}

		@Watch('_item')
		onItemChanged(val: boolean, oldVal: boolean) {
			this.editable = this._item ? this._item.status === 'MANUAL' : false;
		}

		mounted(){
			this.enableExtraMenu = this.getHavePermission('transfer_management');
    }

		closeSettingsHandler(isSave: boolean) {
			// console.log('>> closeSettingsHandler:', isSave);
			this.$emit('update:item', isSave ? this._item : null);
			this._show = false;
		}

		async processKtxSent() {
			try {
				ApiService.Instance.post("transfers/ktx-sent", {transfer_id: this._item.id})
					.then(res => {
						if (res?.data?.message) this.showSnackbar(res?.data?.message);
					})
					.catch(err => {
						console.warn(err);
						if (err?.data?.message) this.showSnackbar(err?.data?.message, 'error');
					});
			} catch (e) {
				console.warn(e);
			}
		}

		async processKtxCancel() {
			try {
				ApiService.Instance.post("transfers/ktx-cancel", {transfer_id: this._item.id})
					.then(res => {
						if (res?.data?.message) this.showSnackbar(res?.data?.message);
					})
					.catch(err => {
						console.warn(err);
						if (err?.data?.message) this.showSnackbar(err?.data?.message, 'error');
					});
			} catch (e) {
				console.warn(e);
			}
		}

		showSnackbar(message: string, type = '') {
			store.dispatch(type === 'error' ? 'MAIN/showSnackbarError' : 'MAIN/showSnackbarInfo', message).then();
		}

		showStatusHistory () {
			this.showStatusesCard = true;
		}
	}
