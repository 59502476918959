








import {Component, Vue} from 'vue-property-decorator';
import Transfers from '@/components/operator/DashboardTransfers.vue';
import Balances from '@/components/operator/DashboardBalances.vue'
import Info from '@/components/operator/DashboardInfo.vue'

@Component({
	components: {
		Balances,
		Transfers,
		Info
	}
})
export default class Dashboard extends Vue {
}
