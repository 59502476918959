
















































import {Component, Vue} from 'vue-property-decorator';
import TransfersList from "@/components/pay-oper/TransfersList.vue";
import {getStatusSettings} from "@/model/transfer";

@Component({
	components: {
		TransfersList
	}
})
export default class TransfersHistory extends Vue {

	// статусы трансферов:
	// CREATED	-	трансфер создан в базе и помещен в очередь на отправку
	// PROCESS	-	трансфер ожидает обработки
	// PAID			-	трансфер оплачен через агента (xt-logic, alikassa)
	// CANCELED	-	трансфер отменен
	// - FOR_SEND	-	трасфер прошел все проверки и будет отправлен
	// - MANUAL		-	требуется ручная обработка трансфера

	anySearch = '';
	statusSelect = [];
	statuses = ['CREATED', 'PROCESS', 'MANUAL', 'PAID', 'CANCELED'];
	filters = {};

	getStatusColor(status: string) {
		const preset = getStatusSettings(status);
		return preset.color;
	}

	applyFilters() {
		this.filters = {
			statuses: this.statusSelect,
			anySearch: this.anySearch
		}
	}

	clearFilters() {
		this.anySearch = '';
		this.statusSelect = [];
		this.filters = {}
	}
}
